import React from "react";
import styled from "styled-components";
import fonts from "../../Styles/fonts";

const Contianer = styled.div`
  width: ${(p) => (p.width ? p.width : "310px")};
  position: relative;
  border-bottom: ${(p) => (p.border ? "none" : "1px solid black")};
  margin-right: 36px;
  margin-bottom: 24px;
  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
`;
const Name = styled.span`
  ${fonts.body2}
  display: block;
  margin-bottom: 16px;
`;
const Select = styled.select`
  ${fonts.body2}
  padding-top: 8px;
  width: 100%;
  border: none;
  outline: none;
`;
const Option = styled.option`
  width: 100%;
`;

const TypeDropDown = ({ width, name, register, formName, value }) => {
  return (
    <>
      <Contianer width={width}>
        <Name>{name}</Name>
        <Select
          {...(register && { ...register(formName, { require: true }) })}
          defaultValue={value}
        >
          <Option value="VOICE">오디오</Option>
          <Option value="TEXT">텍스트</Option>
        </Select>
      </Contianer>
    </>
  );
};

export default TypeDropDown;
