import React from "react";
import { getExactDate, typeConverter } from "../../utils";
import InputAreaItem from "../InputAreaItem";
import InputForm from "../InputForm";
import InputItem from "../InputItem";

const BoardInfo = ({ data }) => {
  const { type, createdAt, page, user, body } = data;
  return (
    <InputForm title="회원 정보">
      <InputItem name="게시물 위치" value={typeConverter(type)} disable />
      <InputItem
        name="작성일"
        value={getExactDate(parseInt(createdAt))}
        disable
      />
      <InputItem name="제목" value={page?.title} disable />
      <InputItem name="글쓴이" value={user?.username} disable />
      <InputAreaItem
        name="콘텐츠 설명"
        border
        value={body}
        disable
        width="100%"
        height="110px"
      />
    </InputForm>
  );
};

export default BoardInfo;
