import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import LayOut from "../../Components/LayOut";
import { useHistory, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import Modal from "../../Components/Modal";
import RandomAlarmInfo from "../../Components/Site/RandomAlarmInfo";

const HEALING = gql`
  query healing($id: Int!) {
    healing(id: $id) {
      id
      title
      body
    }
  }
`;
const ADMIN_DELETE_HEALING = gql`
  mutation adminDeleteHealing($id: [Int]) {
    adminDeleteHealing(id: $id) {
      ok
      error
    }
  }
`;
const ADMIN_UPDATE_HEALING = gql`
  mutation adminUpdateHealing($id: Int!, $title: String, $body: String) {
    adminUpdateHealing(id: $id, title: $title, body: $body) {
      ok
      error
    }
  }
`;

const RandomAlarmDetail = () => {
  const location = useLocation();
  const {
    state: { id },
  } = location;
  const { data, loading } = useQuery(HEALING, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    adminUpdateHealingMutation({
      variables: {
        id,
        title: data.title,
        body: data.body,
      },
    });
  };
  const [saveModal, setSaveModal] = useState(false);
  const [deleteModal, setDeleteModel] = useState(false);
  const onSaveModal = () => setSaveModal(true);
  const onDeleteModal = () => setDeleteModel(true);
  const close = () => {
    setSaveModal(false);
    setDeleteModel(false);
  };
  const [adminDeleteHealingMutation] = useMutation(ADMIN_DELETE_HEALING, {
    onCompleted: (d) => {
      if (d.adminDeleteHealing.ok) {
        close();
        history.push("/randomAlarm");
      }
    },
  });
  const [adminUpdateHealingMutation] = useMutation(ADMIN_UPDATE_HEALING, {
    onCompleted: (d) => {
      if (d.adminUpdateHealing.ok) {
        onSaveModal();
      } else {
        alert(d.adminUpdateHealing.error);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut
        title="랜덤 힐링문구 알림 생성"
        btn1Value="저장하기"
        btn2Value="Q&A 삭제"
        type1="submit"
        btn2Click={onDeleteModal}
        red2
      >
        {!loading && (
          <RandomAlarmInfo register={register} data={{ ...data?.healing }} />
        )}
        <Modal
          visible={saveModal}
          body="저장되었습니다."
          confirm={() => history.push("/randomAlarm")}
        />
        <Modal
          body="알림 삭제 시, 작성된 알림 내용이 삭제되며 복구가 불가능합니다. 알림을 삭제하시겠습니까?"
          visible={deleteModal}
          close={close}
          confirm={() => {
            adminDeleteHealingMutation({
              variables: {
                id: [id],
              },
            });
          }}
        />
      </LayOut>
    </form>
  );
};

export default RandomAlarmDetail;
