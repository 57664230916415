import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { SearchIcon } from "../../Icons/Icons";
import fonts from "../../Styles/fonts";
import { CustomBtn } from "../Buttons";
import CheckBox from "../CheckBox";
import Modal from "../Modal";

const Container = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
  position: relative;
`;

const Shell = styled.span`
  ${fonts.body1};
  :first-child {
    width: 10%;
  }
`;

const Btn = styled.button`
  cursor: pointer;
`;

const ContentsSheetBody = ({
  id,
  part,
  subPart,
  num,
  title,
  selected,
  toggleSelect,
  onDelete,
  contents,
  active,
}) => {
  const type = contents ? part?.type : subPart?.part?.type;
  const history = useHistory();
  const location = useLocation();
  const goContentsDetail = () => {
    history.push({
      pathname: "/contentsDetail",
      state: {
        id,
        type,
        beforePath: location.pathname,
      },
    });
  };
  const goActiveDetail = () => {
    history.push({
      pathname: "/activeDetail",
      state: {
        id,
        type,
        beforePath: location.pathname,
      },
    });
  };
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <Container>
      <CheckBox
        marginRight="32px"
        checked={Boolean(selected.filter((item) => item === id).length !== 0)}
        onClick={() => toggleSelect(id)}
      />
      <Shell style={{ width: "10%" }}>
        <Btn
          type="button"
          onClick={() => {
            contents && goContentsDetail();
            active && goActiveDetail();
          }}
        >
          <SearchIcon />
        </Btn>
      </Shell>
      {contents && (
        <>
          <Shell style={{ width: "20%" }}>{part?.num}</Shell>
          <Shell style={{ width: "20%" }}>{num}</Shell>
          <Shell style={{ width: "40%" }}>{title}</Shell>
          <CustomBtn width="70px" value="삭제" red onClick={onMoal} />
        </>
      )}
      {active && (
        <>
          <Shell style={{ width: "15%" }}>{subPart?.part?.num}</Shell>
          <Shell style={{ width: "15%" }}>{subPart?.num}</Shell>
          <Shell style={{ width: "15%" }}>{num}</Shell>
          <Shell style={{ width: "35%" }}>{title}</Shell>
          <CustomBtn width="70px" value="삭제" red onClick={onMoal} />
        </>
      )}
      <Modal
        body={
          contents
            ? "콘텐츠 삭제 시, 관련 정보와 하위 등록된 활동들도 모두 삭제되며 복구가 불가능합니다. 선택한 콘텐츠를 삭제하시겠습니까?"
            : "활동 삭제 시, 관련 정보와 하위 등록된 세부 활동들도 모두 삭제되며 복구가 불가능합니다. 선택한 활동을 삭제하시겠습니까?"
        }
        visible={isModal}
        close={close}
        confirm={() => onDelete({ variables: { id: [id] } })}
      />
    </Container>
  );
};
export default ContentsSheetBody;
