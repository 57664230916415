import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import SideMenu from "./Screens/SideMenu";
import Login from "./Screens/LoggedOut/Login";
import Main from "./Screens/User/Main";
import styled from "styled-components";
import AppHeader from "./Components/Header/AppHeader";
import Contents from "./Screens/Contents/Contents";
import Board from "./Screens/Board/Board";
import Report from "./Screens/Board/Report";
import RandomAlarm from "./Screens/Site/RandomAlarm";
import Notice from "./Screens/Site/Notice";
import Coupon from "./Screens/Site/Coupon";
import Terms from "./Screens/Site/Terms";
import Policy from "./Screens/Site/Policy";
import Payment from "./Screens/Payment/Payment";
import UserDetail from "./Screens/User/UserDetail";
import ContentsDetail from "./Screens/Contents/ContentsDetail";
import CreateContents from "./Screens/Contents/CreateContents";
import ActiveDetail from "./Screens/Contents/ActiveDetail";
import CreateActive from "./Screens/Contents/CreateActive";
import BoardDetail from "./Screens/Board/BoardDetail";
import ReportDetail from "./Screens/Board/ReportDetail";
import CreateQnA from "./Screens/Site/CreateQnA";
import QnA from "./Screens/Site/QnA";
import QnADetail from "./Screens/Site/QnADetail";
import RandomAlarmDetail from "./Screens/Site/RandomAlarmDetail";
import CreateRandomAlarm from "./Screens/Site/CreateRandomAlarm";
import CreateNotice from "./Screens/Site/CreateNotice";
import NoticeDetail from "./Screens/Site/NoticeDetail";
import CreateCoupon from "./Screens/Site/CreateCoupon";
import CouponDetail from "./Screens/Site/CouponDetail";
import Part from "./Screens/Site/Part";
import PartDetail from "./Screens/Site/PartDetail";

const Constructure = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
`;
const Container = styled.div`
  display: flex;
  height: 100%;
  padding-top: 77px;
`;
const BackGround = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.bgColor};
  padding: 24px 24px 24px 24px;
`;

const LoggedInRoutes = () => {
  return (
    <Router>
      <Constructure>
        <AppHeader />
        <Container>
          <SideMenu />
          <BackGround>
            <Switch>
              <Route exact path="/" component={Main} />
              <Route path="/userDetail" component={UserDetail} />
              <Route path="/recordingC" component={Contents} />
              <Route path="/drawingC" render={() => <Contents />} />
              <Route path="/writingC" render={() => <Contents />} />
              <Route path="/contentsDetail" render={() => <ContentsDetail />} />
              <Route path="/createContents" render={() => <CreateContents />} />
              <Route path="/activeDetail" render={() => <ActiveDetail />} />
              <Route path="/createActive" render={() => <CreateActive />} />
              <Route path="/board" render={() => <Board />} />
              <Route path="/boardDetail" render={() => <BoardDetail />} />
              <Route path="/report" render={() => <Report />} />
              <Route path="/reportDetail" render={() => <ReportDetail />} />
              <Route path="/qna" render={() => <QnA />} />
              <Route path="/part" render={() => <Part />} />
              <Route path="/createQnA" render={() => <CreateQnA />} />
              <Route path="/qnaDetail" render={() => <QnADetail />} />
              <Route path="/partDetail" render={() => <PartDetail />} />
              <Route path="/randomAlarm" render={() => <RandomAlarm />} />
              <Route
                path="/createRandomAlarm"
                render={() => <CreateRandomAlarm />}
              />
              <Route
                path="/randomAlarmDetail"
                render={() => <RandomAlarmDetail />}
              />
              <Route path="/notice" render={() => <Notice />} />
              <Route path="/createNotice" render={() => <CreateNotice />} />
              <Route path="/noticeDetail" render={() => <NoticeDetail />} />
              <Route path="/coupon" render={() => <Coupon />} />
              <Route path="/createCoupon" render={() => <CreateCoupon />} />
              <Route path="/couponDetail" render={() => <CouponDetail />} />
              <Route path="/terms" render={() => <Terms />} />
              <Route path="/policy" render={() => <Policy />} />
              <Route path="/payment" render={() => <Payment />} />
              <Redirect from="*" to="/" />
            </Switch>
          </BackGround>
        </Container>
      </Constructure>
    </Router>
  );
};

const LoggedOutRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Login />} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};
// const test = true;

const AppRouter = ({ isLoggedIn }) =>
  isLoggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />;

export default AppRouter;
