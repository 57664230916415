import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import UserInfo from "../../Components/User/UserInfo";
import { USER_FRAGMENT } from "../../fragments";

const USER = gql`
  query user($id: String!) {
    user(id: $id) {
      ...UserFragment
      recordingPass
      drawingPass
      writingPass
    }
  }
  ${USER_FRAGMENT}
`;

const ADMIN_DELETE_USER = gql`
  mutation adminDeleteUser($id: [String]) {
    adminDeleteUser(id: $id) {
      ok
      error
    }
  }
`;

const ADMIN_UPDATE_USER = gql`
  mutation adminUpdateUser(
    $id: String!
    $username: String
    $recordingPass: Boolean
    $drawingPass: Boolean
    $writingPass: Boolean
  ) {
    adminUpdateUser(
      id: $id
      username: $username
      recordingPass: $recordingPass
      drawingPass: $drawingPass
      writingPass: $writingPass
    ) {
      ok
      error
    }
  }
`;

const UserDetail = ({
  location: {
    state: { id },
  },
}) => {
  const { data, loading } = useQuery(USER, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const getBool = (str) => {
    if (str === "true") {
      return true;
    } else {
      return false;
    }
  };
  const onValid = (data) => {
    adminUpdateUserMutation({
      variables: {
        id,
        username: data.username,
        recordingPass: getBool(data.recordingPass),
        drawingPass: getBool(data.drawingPass),
        writingPass: getBool(data.writingPass),
      },
    });
  };
  const [saveModal, setSaveModal] = useState(false);
  const [deleteModal, setDeleteModel] = useState(false);
  const onSaveModal = () => setSaveModal(true);
  const onDeleteModal = () => setDeleteModel(true);
  const close = () => {
    setSaveModal(false);
    setDeleteModel(false);
  };
  const [adminDeleteUserMutation] = useMutation(ADMIN_DELETE_USER, {
    onCompleted: (d) => {
      if (d.adminDeleteUser.ok) {
        close();
        history.push("/");
      }
    },
  });
  const [adminUpdateUserMutation] = useMutation(ADMIN_UPDATE_USER, {
    onCompleted: (d) => {
      if (d.adminUpdateUser.ok) {
        onSaveModal();
      } else {
        alert(d.adminUpdateUser.error);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut
        title="회원 정보"
        btn1Value="저장하기"
        btn2Value="회원삭제"
        type1="submit"
        red2
        btn2Click={onDeleteModal}
      >
        {!loading && <UserInfo register={register} data={{ ...data?.user }} />}
        <Modal
          visible={saveModal}
          body="저장되었습니다."
          confirm={() => history.push("/")}
        />
        <Modal
          body="회원 삭제 시, 관련 정보의 복구가 불가능합니다. 선택한 회원을 삭제하시겠습니까?"
          visible={deleteModal}
          close={close}
          confirm={() =>
            adminDeleteUserMutation({
              variables: {
                id: [id],
              },
            })
          }
        />
      </LayOut>
    </form>
  );
};

export default UserDetail;
