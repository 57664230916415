import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import InputAreaItem from "../../Components/InputAreaItem";
import InputForm from "../../Components/InputForm";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";

const POLICY = gql`
  query policy {
    policy {
      terms
    }
  }
`;

const ADMIN_UPSERT_POLICY = gql`
  mutation adminUpsertPolicy($terms: String, $privacy: String) {
    adminUpsertPolicy(terms: $terms, privacy: $privacy) {
      ok
      error
    }
  }
`;

const Terms = () => {
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    adminUpsertPolicyMutation({
      variables: {
        terms: data.terms,
      },
    });
  };
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => {
    setIsModal(false);
  };
  const { data } = useQuery(POLICY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const [adminUpsertPolicyMutation] = useMutation(ADMIN_UPSERT_POLICY, {
    onCompleted: (d) => {
      if (d.adminUpsertPolicy.ok) {
        onMoal();
      } else {
        alert(d.adminUpsertPolicy.error);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut title="이용약관  관리" btn1Value="저장하기" type1="submit">
        <InputForm title="이용약관 정보">
          <InputAreaItem
            name="이용약관 내용"
            border
            width="100%"
            height="590px"
            register={register}
            formName="terms"
            value={data?.policy?.terms}
          />
        </InputForm>
      </LayOut>
      <Modal visible={isModal} body="저장되었습니다." confirm={close} />
    </form>
  );
};
export default Terms;
