import React from "react";
import styled from "styled-components";
import fonts from "../Styles/fonts";

const Container = styled.div`
  margin-bottom: 36px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.span`
  ${fonts.subtitle1}
  padding-left: 8px;
  margin-bottom: 24px;
  display: block;
`;

const InputSection = styled.div`
  width: 100%;
  box-shadow: ${(p) => p.theme.shadow};
  border-radius: 8px;
  padding: 24px 24px 0 24px;
  display: flex;
  flex-wrap: wrap;
`;

const InputForm = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <InputSection>{children}</InputSection>
    </Container>
  );
};

export default InputForm;
