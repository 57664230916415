import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import ActiveInfo from "../../Components/Contents/ActiveInfo";
import CreateDetailActive from "../../Components/Contents/CreateDetailActive";
import DetailActiveInfo from "../../Components/Contents/DetailActiveInfo";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import { pathConverter } from "../../utils";

const ADMIN_GET_PAGE = gql`
  query adminGetPage($id: Int!) {
    adminGetPage(id: $id) {
      id
      title
      description
      num
      thumbnail
      subPart {
        id
        num
        part {
          id
          num
          type
        }
      }
    }
  }
`;

const ADMIN_UPDATE_PAGE = gql`
  mutation adminUpdatePage(
    $id: Int!
    $title: String
    $description: String
    $num: Int
    $file: Upload
    $subPartId: Int
  ) {
    adminUpdatePage(
      id: $id
      title: $title
      description: $description
      num: $num
      file: $file
      subPartId: $subPartId
    ) {
      ok
      error
    }
  }
`;

const ADMIN_DELETE_PAGE = gql`
  mutation adminDeletePage($id: [Int]) {
    adminDeletePage(id: $id) {
      ok
      error
    }
  }
`;

const ADMIN_GET_PAGECONTENTS = gql`
  query adminGetPageContents($pageId: Int!) {
    adminGetPageContents(pageId: $pageId) {
      id
      num
      contentType
      body
      vimeoId
    }
  }
`;

const ActiveDetail = () => {
  const location = useLocation();
  const {
    state: { id, type },
  } = location;
  const { data, loading } = useQuery(ADMIN_GET_PAGE, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: cData,
    loading: cLoading,
    refetch,
  } = useQuery(ADMIN_GET_PAGECONTENTS, {
    variables: { pageId: id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();
  const { register, handleSubmit, watch, setValue } = useForm({
    mode: "onChange",
  });
  const onValid = (data) => {
    if (!data.partId || !(data.subPartId !== "콘텐츠 번호 선택")) {
      alert("파트 번호 혹은 콘텐츠 번호를 선택해 주세요.");
      return;
    }
    if (!data.num) {
      alert("활동 번호를 입력해 주세요.");
      return;
    }
    adminUpdatePageMutation({
      variables: {
        id,
        title: data.title,
        description: data.description,
        num: parseInt(data.num),
        subPartId: parseInt(data.subPartId),
        file: Boolean(data.thumbnail[0]) ? data.thumbnail[0] : undefined,
      },
    });
  };
  const [saveModal, setSaveModal] = useState(false);
  const [deleteModal, setDeleteModel] = useState(false);
  const onSaveModal = () => setSaveModal(true);
  const onDeleteModal = () => setDeleteModel(true);
  const close = () => {
    setSaveModal(false);
    setDeleteModel(false);
  };
  const [adminDeletePageMutation] = useMutation(ADMIN_DELETE_PAGE, {
    onCompleted: (d) => {
      if (d.adminDeletePage.ok) {
        close();
        history.push(pathConverter(type));
      }
    },
  });
  const [adminUpdatePageMutation] = useMutation(ADMIN_UPDATE_PAGE, {
    onCompleted: (d) => {
      if (d.adminUpdatePage.ok) {
        onSaveModal();
      } else {
        alert(d.adminUpdatePage.error);
      }
    },
    onError: (e) => alert(e),
  });
  return (
    <>
      <form onSubmit={handleSubmit(onValid)} style={{ marginBottom: "24px" }}>
        <LayOut
          title="활동 정보"
          btn1Value="저장하기"
          btn2Value="활동 삭제"
          type1="submit"
          btn2Click={onDeleteModal}
          red2
        >
          {!loading && (
            <ActiveInfo
              watch={watch}
              register={register}
              setValue={setValue}
              data={{ ...data?.adminGetPage }}
            />
          )}
        </LayOut>
        <Modal
          visible={saveModal}
          body="저장되었습니다."
          confirm={() => history.push(pathConverter(type))}
        />
        <Modal
          body="콘텐츠 삭제 시, 관련 정보와 하위 등록된 활동들도 모두 삭제되며 복구가 불가능합니다. 선택한 콘텐츠를 삭제하시겠습니까?"
          visible={deleteModal}
          close={close}
          confirm={() => {
            adminDeletePageMutation({
              variables: {
                id: [id],
              },
            });
          }}
        />
      </form>
      <LayOut title="세부 활동 관리">
        {!cLoading &&
          cData?.adminGetPageContents?.map((item) => (
            <DetailActiveInfo
              marginBottom="24px"
              data={{ ...item }}
              refetch={refetch}
            />
          ))}
        <CreateDetailActive pageId={id} refetch={refetch} />
      </LayOut>
    </>
  );
};

export default ActiveDetail;
