import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { logUserIn } from "../../client";
import { BlueInputBtn } from "../../Components/Buttons";
// import { BlueInputBtn } from "../../Components/Buttons";
import { LockIcon } from "../../Icons/Icons";
import FormError from "../../Components/FormError";

const BackGround = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.bgColor};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 16px 16px 16px;
  background-color: ${(p) => p.theme.white};
  justify-content: center;
  border-radius: 12px;
  form {
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: center;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
`;
const Text = styled.span`
  font-size: 16px;
  color: ${(p) => p.theme.grey};
  margin-bottom: 24px;
  display: block;
  text-align: center;
`;
const Input = styled.input`
  width: 305px;
  padding: 12px 16px;
  border: 1px solid
    ${(props) => (props.hasError ? "tomato" : props.theme.border)};
  border-radius: 8px;
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : 0)};
`;

const ADMIN_LOGIN_MUTATION = gql`
  mutation adminLogin($username: String!, $password: String!) {
    adminLogin(username: $username, password: $password) {
      ok
      token
      error
    }
  }
`;

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    setError,
    clearErrors,
  } = useForm({ mode: "onChange" });
  const onSubmitValid = (data) => {
    if (loading) {
      return;
    }
    loginMutation({
      variables: {
        ...data,
      },
    });
  };
  const onCompleted = async (data) => {
    const {
      adminLogin: { ok, error, token },
    } = data;
    if (!ok) {
      setError("result", { message: error });
    }
    if (token) {
      logUserIn(token);
    }
  };
  const [loginMutation, { loading }] = useMutation(ADMIN_LOGIN_MUTATION, {
    onCompleted,
  });
  const clearLoginError = () => {
    clearErrors("result");
  };
  return (
    <BackGround>
      <Container>
        <IconWrapper>
          <LockIcon />
        </IconWrapper>
        <Text>떠나찾 관리자페이지 로그인</Text>
        <form onSubmit={handleSubmit(onSubmitValid)}>
          <Input
            placeholder="아이디*"
            marginBottom="10px"
            onFocus={clearLoginError}
            hasError={Boolean(errors?.username?.message)}
            {...register("username", { required: "아이디를 입력해 주세요." })}
          />
          <FormError message={errors?.username?.message} />
          <Input
            type="password"
            placeholder="비밀번호*"
            marginBottom="10px"
            onFocus={clearLoginError}
            hasError={Boolean(errors?.password?.message)}
            {...register("password", { required: "비밀번호를 입력해 주세요." })}
          />
          <FormError message={errors?.password?.message} />
          <BlueInputBtn
            type="submit"
            value={loading ? "Loading..." : "SIGN IN"}
            disabled={!isValid || loading}
          />
          <FormError message={errors?.result?.message} />
        </form>
      </Container>
    </BackGround>
  );
};

export default Login;
