import React from "react";
import styled, { css } from "styled-components";
import fonts from "../Styles/fonts";

const InputBtn = styled.input`
  cursor: pointer;
  background-color: ${(p) => p.theme.blue};
  padding: 8px 0;
  border-radius: 8px;
  font-size: 16px;
  color: ${(p) => p.theme.white};
  width: ${(p) => (p.width ? p.width : "100%")};
  text-align: center;
`;

export const BlueInputBtn = ({ width, type, value }) => {
  return <InputBtn width={width} type={type} value={value} />;
};

const Btn = styled.button`
  ${fonts.subtitle1}
  cursor: pointer;
  background-color: ${(p) => (p.red ? p.theme.red : p.theme.blue)};
  padding: 5px 12px;
  border-radius: 4px;
  color: ${(p) => p.theme.white};
  width: ${(p) => (p.width ? p.width : "110px")};
  text-align: center;
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : 0)};
  ${(p) =>
    p.outline &&
    css`
      background-color: #fff;
      color: ${(p) => p.theme.blue};
      border: 1px solid ${(p) => p.theme.blue};
    `}
`;

export const CustomBtn = ({
  width,
  value,
  red,
  marginLeft,
  onClick,
  outline,
  type,
}) => {
  return (
    <Btn
      type={type ? type : "button"}
      width={width}
      red={red}
      outline={outline}
      marginLeft={marginLeft}
      onClick={() => onClick && onClick()}
    >
      {value}
    </Btn>
  );
};
