import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import InputAreaItem from "../../Components/InputAreaItem";
import InputForm from "../../Components/InputForm";
import InputItem from "../../Components/InputItem";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";

const ADMIN_CREATE_FAQ = gql`
  mutation adminCreateFAQ($title: String, $body: String) {
    adminCreateFAQ(title: $title, body: $body) {
      ok
      error
    }
  }
`;

const CreateQnA = () => {
  const [adminCreateFaqMutation] = useMutation(ADMIN_CREATE_FAQ, {
    onCompleted: (d) => {
      if (d.adminCreateFAQ.ok) {
        onMoal();
      } else {
        alert(d.adminCreateFAQ.error);
      }
    },
  });
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    adminCreateFaqMutation({
      variables: {
        title: data.title,
        body: data.body,
      },
    });
  };
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => {
    setIsModal(false);
    history.push("/qna");
  };

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut
        title="자주 묻는 질문(Q&A) 생성"
        btn1Value="저장하기"
        type1="submit"
      >
        <InputForm title="자주 묻는 질문(Q&A) 정보">
          <InputItem
            name="게시글 제목"
            width="320px"
            placeholder="게시글 제목을 입력해주세요."
            border
            register={register}
            formName="title"
          />

          <InputAreaItem
            name="게시글 내용"
            placeholder="게시글 내용을 입력해주세요."
            border
            width="100%"
            height="110px"
            register={register}
            formName="body"
          />
        </InputForm>
        <Modal visible={isModal} body="저장되었습니다." confirm={close} />
      </LayOut>
    </form>
  );
};

export default CreateQnA;
