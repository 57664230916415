import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import Pagenation from "../../Components/Sheet/Pagenation";
import UserSheetBody from "../../Components/Sheet/UserSheetBody";
import UserSheetHeader from "../../Components/Sheet/UserSheetHeader";
import { UserColumns } from "../../Data/user/UserGrid";
import { USER_FRAGMENT } from "../../fragments";

const Container = styled.div`
  height: 615px;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  overflow-y: scroll;
  margin-bottom: 32px;
`;

const USERS = gql`
  query users($page: Int!, $keyword: String) {
    users(page: $page, keyword: $keyword) {
      ok
      error
      users {
        ...UserFragment
      }
      totalPages
    }
  }
  ${USER_FRAGMENT}
`;
const ADMIN_DELETE_USER = gql`
  mutation adminDeleteUser($id: [String]) {
    adminDeleteUser(id: $id) {
      ok
      error
    }
  }
`;

const Main = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(undefined);
  const [selected, setSelected] = useState([]);
  const toggleSelectUser = (id) => {
    if (selected.filter((user) => user === id).length !== 0) {
      setSelected(selected.filter((user) => user !== id));
    } else {
      setSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const selectAll = () => {
    if (selected.length < data?.users?.users?.length) {
      const prevArray = [];
      data?.users?.users?.map((user) => {
        if (selected.filter((id) => user === id).length === 0) {
          prevArray.push(user.id);
        }
        return null;
      });
      setSelected(prevArray);
    } else {
      setSelected([]);
    }
  };
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading, refetch } = useQuery(USERS, {
    variables: { page, keyword },
    onCompleted: (d) => {
      if (d.users.ok) {
        setTotalPages(d.users.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setSelected([]);
    setPage(page);
  };
  const [adminDeleteUserMutation, { loading: mutationLoading }] = useMutation(
    ADMIN_DELETE_USER,
    {
      onCompleted: (d) => {
        setPage(1);
        close();
        refetch();
      },
    }
  );
  const onDeleteAll = () => {
    if (!mutationLoading) {
      adminDeleteUserMutation({
        variables: { id: selected },
        onCompleted: (d) => {
          setPage(1);
          close();
          refetch();
        },
      });
    }
  };
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <LayOut title="회원관리" btn1Value="선택 삭제" red1 btn1Click={onMoal}>
      <Search value={keyword} onChangeValue={onChangeKeyword} />
      <Container>
        {!loading && (
          <UserSheetHeader
            UserColumns={UserColumns}
            checked={selected.length === data?.users?.users?.length}
            onClick={selectAll}
          />
        )}
        {!loading &&
          data?.users?.users?.map((item) => (
            <UserSheetBody
              {...item}
              key={item.id}
              selected={selected}
              toggleSelectUser={toggleSelectUser}
              onDelete={adminDeleteUserMutation}
            />
          ))}
      </Container>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      <Modal
        body="회원 삭제 시, 관련 정보의 복구가 불가능합니다. 선택한 회원을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={onDeleteAll}
      />
    </LayOut>
  );
};
export default Main;
