export const NoticeColumns = {
  first: "상세보기",
  second: "공지사항 제목",
};

export const NoticeData = [
  {
    id: 1,
    title: "공지사항 제목 예시입니다.1",
  },
  {
    id: 2,
    title: "공지사항 제목 예시입니다.2",
  },
  {
    id: 3,
    title: "공지사항 제목 예시입니다.3",
  },
  {
    id: 4,
    title: "공지사항 제목 예시입니다.4",
  },
  {
    id: 5,
    title: "공지사항 제목 예시입니다.5",
  },
  {
    id: 6,
    title: "공지사항 제목 예시입니다.6",
  },
  {
    id: 7,
    title: "공지사항 제목 예시입니다.7",
  },
  {
    id: 8,
    title: "공지사항 제목 예시입니다.8",
  },
  {
    id: 9,
    title: "공지사항 제목 예시입니다.9",
  },
  {
    id: 10,
    title: "공지사항 제목 예시입니다.10",
  },
];
