import React from "react";
import styled from "styled-components";
import { SearchIcon } from "../Icons/Icons";

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #000;
  padding-bottom: 8px;
`;
const Btn = styled.button`
  margin-right: 16px;
`;

const Input = styled.input`
  width: 200px;
`;

const Search = ({ value, onChangeValue, data1, data2, data3, children }) => {
  return (
    <Section>
      {children}
      {/* {data1 && <SearchDropdown data={data1} />}
      {data2 && <SearchDropdown data={data2} />}
      {data3 && <SearchDropdown data={data3} />} */}
      <Container>
        <Btn>
          <SearchIcon />
        </Btn>
        <Input placeholder="검색" value={value} onChange={onChangeValue} />
      </Container>
    </Section>
  );
};

export default Search;
