import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { MinusIcon, PlusIcon } from "../Icons/Icons";
import fonts from "../Styles/fonts";
import MenuList from "./MenuList";

const Container = styled.button`
  width: 100%;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.menuBorder};
  cursor: pointer;
`;

const MenuTitle = styled.span`
  ${fonts.body1}
  font-size: 16px;
  color: ${(p) => p.theme.menuTitle};
`;

const MenuBox = ({ title, listItems, name, isFocused, onClickFocus }) => {
  const location = useLocation();
  const current = location.pathname;
  const beforePath = location.state?.beforePath;
  const innerPage =
    current.toLowerCase().includes("create") ||
    current.toLowerCase().endsWith("detail");
  return (
    <>
      <Container onClick={() => onClickFocus(name)}>
        <MenuTitle>{title}</MenuTitle>
        {isFocused ? <MinusIcon /> : <PlusIcon />}
      </Container>
      {isFocused && (
        <>
          {listItems.map((item) => (
            <MenuList
              {...item}
              key={item.id}
              current={innerPage ? beforePath : current}
            />
          ))}
        </>
      )}
    </>
  );
};

export default MenuBox;
