export const MenuListData = [
  {
    id: 1,
    name: "user",
    title: "회원 관리",
    listItems: [{ id: 1, title: "회원 관리", path: "/" }],
  },
  {
    id: 2,
    name: "contents",
    title: "콘텐츠 관리",
    listItems: [
      { id: 1, title: "레코딩 콘텐츠", path: "/recordingC" },
      { id: 2, title: "드로잉 콘텐츠", path: "/drawingC" },
      { id: 3, title: "라이팅 콘텐츠 ", path: "/writingC" },
    ],
  },
  {
    id: 3,
    name: "board",
    title: "게시판 관리",
    listItems: [
      { id: 1, title: "게시물 관리", path: "/board" },
      { id: 2, title: "신고내역 관리", path: "/report" },
    ],
  },
  {
    id: 4,
    name: "site",
    title: "사이트 관리",
    listItems: [
      { id: 1, title: "자주 묻는 질문 관리", path: "/qna" },
      { id: 2, title: "파트 이미지 관리", path: "/part" },
      { id: 3, title: "랜덤 힐링문구 알림 관리", path: "/randomAlarm" },
      { id: 4, title: "공지사항 관리", path: "/notice" },
      { id: 5, title: "쿠폰번호 관리", path: "/coupon" },
      { id: 6, title: "이용약관 관리", path: "/terms" },
      { id: 7, title: "개인정보처리방침 관리", path: "/policy" },
    ],
  },
  {
    id: 5,
    name: "payment",
    title: "결제 내역",
    listItems: [{ id: 1, title: "결제 내역", path: "/payment" }],
  },
];
