export const BoardColumns = {
  first: "상세보기",
  second: "게시물 위치",
  third: "글제목",
  fourth: "글쓴이",
  fifth: "작성인",
};

export const BoardData = [
  {
    id: 1,
    location: "레코딩 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자1",
    createdAt: "21.08.23",
  },
  {
    id: 2,
    location: "드로잉 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자2",
    createdAt: "21.08.23",
  },
  {
    id: 3,
    location: "라이등 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자3",
    createdAt: "21.08.23",
  },
  {
    id: 4,
    location: "레코딩 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자4",
    createdAt: "21.08.23",
  },
  {
    id: 5,
    location: "드로잉 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자4",
    createdAt: "21.08.23",
  },
  {
    id: 6,
    location: "라이팅 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자3",
    createdAt: "21.08.23",
  },
  {
    id: 7,
    location: "레코딩 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자3",
    createdAt: "21.08.23",
  },
  {
    id: 8,
    location: "레코딩 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자2",
    createdAt: "21.08.23",
  },
  {
    id: 9,
    location: "레코딩 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자1",
    createdAt: "21.08.23",
  },
  {
    id: 10,
    location: "레코딩 게시판",
    title: "테스트 게시물입니다.",
    owner: "사용자1",
    createdAt: "21.08.23",
  },
];
