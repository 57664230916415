import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import Pagenation from "../../Components/Sheet/Pagenation";
import PaymentSheetBody from "../../Components/Sheet/PaymentSheetBody";
import PaymentSheetHeader from "../../Components/Sheet/PaymentSheetHeader";
import { PaymentColumns } from "../../Data/payment/Payment";

const Container = styled.div`
  height: 615px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const PAYMENTS = gql`
  query payments($page: Int, $keyword: String) {
    payments(page: $page, keyword: $keyword) {
      ok
      error
      payments {
        id
        createdAt
        type
        user {
          id
          username
        }
      }
      totalPages
    }
  }
`;

const Payment = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(undefined);
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading } = useQuery(PAYMENTS, {
    variables: { page, keyword },
    onCompleted: (d) => {
      if (d.payments.ok) {
        setTotalPages(d.payments.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setPage(page);
  };
  const [isModal, setIsModal] = useState(false);
  // const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <LayOut
      title="결제 내역"
      // btn1Value="선택 삭제" red1 btn1Click={onMoal}
    >
      <Search value={keyword} onChangeValue={onChangeKeyword} />
      <Container>
        <PaymentSheetHeader UserColumns={PaymentColumns} />
        {!loading &&
          data?.payments?.payments?.map((item) => (
            <PaymentSheetBody
              {...item}
              key={item.id}
              selected={[]}
              toggleSelectFaq={() => null}
              onDelete={() => null}
            />
          ))}
      </Container>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      <Modal
        body="결제 내역 삭제 시, 삭제된 결제 내역은 복구가 불가능합니다. 선택한 결제 내역을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={close}
      />
    </LayOut>
  );
};
export default Payment;
