import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import Pagenation from "../../Components/Sheet/Pagenation";
import ReportSheetBody from "../../Components/Sheet/ReportSheetBody";
import ReportSheetHeader from "../../Components/Sheet/ReportSheetHeader";
import { ReportColumns } from "../../Data/board/Report";
import { SearchReportList } from "../../Data/DropdownData";
import SearchDropdown from "../../Components/Dropdown/SearchDropdown";

const Container = styled.div`
  height: 615px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const ADMIN_GET_REPORTS = gql`
  query adminGetReports($page: Int, $keyword: String, $type: ReportType) {
    adminGetReports(page: $page, keyword: $keyword, type: $type) {
      ok
      error
      reports {
        id
        type
        body
        user {
          username
        }
        createdAt
      }
      totalPages
    }
  }
`;
const ADMIN_DELETE_REPORT = gql`
  mutation adminDeleteReport($id: [Int]) {
    adminDeleteReport(id: $id) {
      ok
      error
    }
  }
`;

const Report = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState(undefined);
  const [keyword, setKeyword] = useState(undefined);
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  const [selected, setSelected] = useState([]);
  const toggleSelectReport = (id) => {
    if (selected.filter((report) => report === id).length !== 0) {
      setSelected(selected.filter((report) => report !== id));
    } else {
      setSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const selectAll = () => {
    if (selected.length < data?.adminGetReports?.reports?.length) {
      const prevArray = [];
      data?.adminGetReports?.reports?.map((report) => {
        if (selected.filter((id) => report === id).length === 0) {
          prevArray.push(report.id);
        }
        return null;
      });
      setSelected(prevArray);
    } else {
      setSelected([]);
    }
  };
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading, refetch } = useQuery(ADMIN_GET_REPORTS, {
    variables: { page, keyword, type: type === "" ? undefined : type },
    onCompleted: (d) => {
      if (d.adminGetReports.ok) {
        setTotalPages(d.adminGetReports.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setSelected([]);
    setPage(page);
  };
  const [adminDeleteReportMutation, { loading: mutationLoading }] = useMutation(
    ADMIN_DELETE_REPORT,
    {
      onCompleted: (d) => {
        setPage(1);
        close();
        refetch();
      },
    }
  );
  const onDeleteAll = () => {
    if (!mutationLoading) {
      adminDeleteReportMutation({
        variables: { id: selected },
        onCompleted: (d) => {
          setPage(1);
          close();
          refetch();
        },
      });
    }
  };
  return (
    <LayOut title="신고내역 관리" btn1Value="선택 삭제" red1 btn1Click={onMoal}>
      <Search value={keyword} onChangeValue={onChangeKeyword}>
        <SearchDropdown
          data={SearchReportList}
          defaultName="신고 유형"
          value={type}
          setValue={setType}
          reset={() => null}
        />
      </Search>
      <Container>
        {!loading && (
          <ReportSheetHeader
            UserColumns={ReportColumns}
            checked={selected.length === data?.adminGetReports?.reports?.length}
            onClick={selectAll}
          />
        )}
        {!loading &&
          data?.adminGetReports?.reports?.map((item) => (
            <ReportSheetBody
              {...item}
              key={item.id}
              selected={selected}
              toggleSelectPost={toggleSelectReport}
              onDelete={adminDeleteReportMutation}
            />
          ))}
      </Container>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      <Modal
        body="신고 삭제 시, 작성된 신고 내용이 삭제되며 복구가 불가능합니다. 선택한 신고를 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={onDeleteAll}
      />
    </LayOut>
  );
};
export default Report;
