import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import ContentsSheetBody from "../../Components/Sheet/ContentsSheetBody";
import ContentsSheetHeader from "../../Components/Sheet/ContentsSheetHeader";
import Pagenation from "../../Components/Sheet/Pagenation";

import { ActiveColumns, ContentsColumns } from "../../Data/contents/Contetns";
import { SearchPartList } from "../../Data/DropdownData";
import SearchDropdown from "../../Components/Dropdown/SearchDropdown";

const Container = styled.div`
  min-height: 100%;
  height: fit-content;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const ADMIN_GET_SUBPARTS = gql`
  query adminGetSubParts(
    $page: Int
    $keyword: String
    $type: MainType
    $partId: Int
    $subPartId: Int
  ) {
    adminGetSubParts(
      page: $page
      keyword: $keyword
      type: $type
      partId: $partId
      subPartId: $subPartId
    ) {
      ok
      error
      subParts {
        id
        num
        title
        description

        part {
          id
          num
          type
        }
      }
      totalPages
    }
  }
`;
const ADMIN_GET_SUBPARTS_IN_PART = gql`
  query adminGetSubPartsInPart($partNum: Int, $type: MainType) {
    adminGetSubPartsInPart(partNum: $partNum, type: $type) {
      id
      num
    }
  }
`;
const ADMIN_GET_PAGES_IN_SUBPART = gql`
  query adminGetPagesInSubPart($subPartId: Int) {
    adminGetPagesInSubPart(subPartId: $subPartId) {
      id
      num
    }
  }
`;

const ADMIN_GET_PAGES = gql`
  query adminGetPages(
    $page: Int
    $keyword: String
    $type: MainType
    $partId: Int
    $subPartId: Int
    $pageId: Int
  ) {
    adminGetPages(
      page: $page
      keyword: $keyword
      type: $type
      partId: $partId
      subPartId: $subPartId
      pageId: $pageId
    ) {
      ok
      error
      pages {
        id
        num
        title
        description

        subPart {
          id
          num
          part {
            id
            num
            type
          }
        }
      }
      totalPages
    }
  }
`;

const ADMIN_DELETE_SUBPART = gql`
  mutation adminDeleteSubPart($id: [Int]) {
    adminDeleteSubPart(id: $id) {
      ok
      error
    }
  }
`;

const ADMIN_DELETE_PAGE = gql`
  mutation adminDeletePage($id: [Int]) {
    adminDeletePage(id: $id) {
      ok
      error
    }
  }
`;

const Contents = () => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    setPartId("");
    setSubPartId(undefined);
  }, [location]);
  const contentsTypeFuc = () => {
    const { pathname } = location;
    if (pathname === "/recordingC") {
      return "RECORDING";
    } else if (pathname === "/drawingC") {
      return "DRAWING";
    } else if (pathname === "/writingC") {
      return "WRITING";
    }
  };
  const contentsType = contentsTypeFuc();
  const goCreateContents = () =>
    history.push("/createContents", {
      type: contentsTypeFuc(),
      beforePath: location.pathname,
    });
  const goCreateActive = () =>
    history.push("/createActive", {
      type: contentsTypeFuc(),
      beforePath: location.pathname,
    });
  const [isContentsModal, setIsContentsModal] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const onContentsMoal = () => setIsContentsModal(true);
  const onActiveMoal = () => setIsActiveModal(true);
  const close = () => {
    setIsContentsModal(false);
    setIsActiveModal(false);
  };
  //subPart
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(undefined);
  const [selected, setSelected] = useState([]);
  const [partId, setPartId] = useState(undefined);
  const [subPartId, setSubPartId] = useState(undefined);
  const toggleSelectSubPart = (id) => {
    if (selected.filter((subPart) => subPart === id).length !== 0) {
      setSelected(selected.filter((subPart) => subPart !== id));
    } else {
      setSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const selectAll = () => {
    if (selected.length < data?.adminGetSubParts?.subParts?.length) {
      const prevArray = [];
      data?.adminGetSubParts?.subParts?.map((subPart) => {
        if (selected.filter((id) => subPart === id).length === 0) {
          prevArray.push(subPart.id);
        }
        return null;
      });
      setSelected(prevArray);
    } else {
      setSelected([]);
    }
  };
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading, refetch } = useQuery(ADMIN_GET_SUBPARTS, {
    variables: {
      page,
      keyword,
      type: contentsType,
      partId: parseInt(partId),
      subPartId: parseInt(subPartId),
    },
    onCompleted: (d) => {
      if (d.adminGetSubParts.ok) {
        setTotalPages(d.adminGetSubParts.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const { data: subPartsData } = useQuery(ADMIN_GET_SUBPARTS_IN_PART, {
    variables: { partNum: parseInt(partId), type: contentsType },
    skip: !partId,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setSelected([]);
    setPage(page);
  };
  const [adminDeleteSubPartMutation, { loading: mutationLoading }] =
    useMutation(ADMIN_DELETE_SUBPART, {
      onCompleted: (d) => {
        setPage(1);
        close();
        refetch();
      },
    });
  const onDeleteAll = () => {
    if (!mutationLoading) {
      adminDeleteSubPartMutation({
        variables: { id: selected },
        onCompleted: (d) => {
          setPage(1);
          close();
          refetch();
        },
      });
    }
  };

  //page
  const [pPage, setPpage] = useState(1);
  const [totalPpages, setTotalPpages] = useState(1);
  const [pKeyword, setPKeyword] = useState(undefined);
  const [pSelected, setPSelected] = useState([]);
  const [pPartId, setPpartId] = useState(undefined);
  const [pSubPartId, setPSubPartId] = useState(undefined);
  const [pageId, setPageId] = useState(undefined);
  const toggleSelectPage = (id) => {
    if (pSelected.filter((page) => page === id).length !== 0) {
      setPSelected(pSelected.filter((page) => page !== id));
    } else {
      setPSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const pSelectAll = () => {
    if (pSelected.length < pData?.adminGetPages?.pages?.length) {
      const prevArray = [];
      pData?.adminGetPages?.pages?.map((page) => {
        if (pSelected.filter((id) => page === id).length === 0) {
          prevArray.push(page.id);
        }
        return null;
      });
      setPSelected(prevArray);
    } else {
      setPSelected([]);
    }
  };
  const pOnChangeKeyword = (e) => {
    setPpage(1);
    setPKeyword(e.target.value);
  };
  const {
    data: pData,
    loading: pLoading,
    refetch: pRefetch,
  } = useQuery(ADMIN_GET_PAGES, {
    variables: {
      page: pPage,
      keyword: pKeyword,
      type: contentsType,
      partId: parseInt(pPartId),
      subPartId: parseInt(pSubPartId),
      pageId: parseInt(pageId),
    },
    onCompleted: (d) => {
      if (d.adminGetPages.ok) {
        setTotalPpages(d.adminGetPages.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const { data: pSubPartsData } = useQuery(ADMIN_GET_SUBPARTS_IN_PART, {
    variables: { partNum: parseInt(pPartId), type: contentsType },
    skip: !pPartId,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const { data: pagesData } = useQuery(ADMIN_GET_PAGES_IN_SUBPART, {
    variables: { subPartId: parseInt(pSubPartId) },
    skip: !pSubPartId,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const pOnClickPage = (page) => {
    setPSelected([]);
    setPpage(page);
  };
  const [adminDeletePageMutation, { loading: pMutationLoading }] = useMutation(
    ADMIN_DELETE_PAGE,
    {
      onCompleted: (d) => {
        setPpage(1);
        close();
        pRefetch();
      },
    }
  );
  const pOnDeleteAll = () => {
    if (!pMutationLoading) {
      adminDeletePageMutation({
        variables: { id: pSelected },
        onCompleted: (d) => {
          setPpage(1);
          close();
          pRefetch();
        },
      });
    }
  };
  return (
    <>
      <LayOut
        title="콘텐츠 관리"
        btn1Value="콘텐츠 생성"
        btn2Value="선택 삭제"
        btn1Click={goCreateContents}
        btn2Click={onContentsMoal}
        red2
        marginBottom="24px"
      >
        <Search value={keyword} onChangeValue={onChangeKeyword}>
          <SearchDropdown
            data={SearchPartList}
            defaultName="전체 파트"
            value={partId}
            setValue={setPartId}
            reset={() => setSubPartId(undefined)}
          />
          <SearchDropdown
            queryData={subPartsData?.adminGetSubPartsInPart}
            defaultName="전체 콘텐츠"
            value={subPartId}
            setValue={setSubPartId}
          />
        </Search>
        <Container>
          {!loading && (
            <ContentsSheetHeader
              Columns={ContentsColumns}
              checked={
                selected.length === data?.adminGetSubParts?.subParts?.length
              }
              onClick={selectAll}
            />
          )}
          {!loading &&
            data?.adminGetSubParts?.subParts?.map((item) => (
              <ContentsSheetBody
                {...item}
                key={item.id}
                selected={selected}
                toggleSelect={toggleSelectSubPart}
                onDelete={adminDeleteSubPartMutation}
                contents
              />
            ))}
        </Container>
        <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      </LayOut>

      <LayOut
        title="활동 관리"
        btn1Value="활동 생성"
        btn2Value="선택 삭제"
        red2
        btn1Click={goCreateActive}
        btn2Click={onActiveMoal}
      >
        <Search value={pKeyword} onChangeValue={pOnChangeKeyword}>
          <SearchDropdown
            data={SearchPartList}
            defaultName="전체 파트"
            value={pPartId}
            setValue={setPpartId}
            reset={() => {
              setPSubPartId(undefined);
              setPageId(undefined);
            }}
          />
          <SearchDropdown
            queryData={pSubPartsData?.adminGetSubPartsInPart}
            defaultName="전체 콘텐츠"
            value={pSubPartId}
            setValue={setPSubPartId}
            reset={() => setPageId(undefined)}
          />
          <SearchDropdown
            queryData={pagesData?.adminGetPagesInSubPart}
            defaultName="전체 활동"
            value={pageId}
            setValue={setPageId}
          />
        </Search>
        <Container>
          {!pLoading && (
            <ContentsSheetHeader
              ActiveColumns={ActiveColumns}
              checked={pSelected.length === pData?.adminGetPages?.pages?.length}
              onClick={pSelectAll}
            />
          )}
          {!pLoading &&
            pData?.adminGetPages?.pages?.map((item) => (
              <ContentsSheetBody
                {...item}
                key={item.id}
                selected={pSelected}
                toggleSelect={toggleSelectPage}
                onDelete={adminDeletePageMutation}
                active
              />
            ))}
        </Container>
        <Pagenation
          total={totalPpages}
          page={pPage}
          onClickPage={pOnClickPage}
        />
      </LayOut>
      <Modal
        body={
          isContentsModal
            ? "콘텐츠 삭제 시, 관련 정보와 하위 등록된 활동들도 모두 삭제되며 복구가 불가능합니다. 선택한 콘텐츠를 삭제하시겠습니까?"
            : "활동 삭제 시, 관련 정보와 하위 등록된 세부 활동들도 모두 삭제되며 복구가 불가능합니다. 선택한 활동을 삭제하시겠습니까?"
        }
        visible={isContentsModal || isActiveModal}
        close={close}
        confirm={isContentsModal ? onDeleteAll : pOnDeleteAll}
      />
    </>
  );
};
export default Contents;
