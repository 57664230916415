import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import CouponDropdown from "../../Components/Dropdown/CouponDropdown";
import InputForm from "../../Components/InputForm";
import InputItem from "../../Components/InputItem";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import { randomCode } from "../../utils";

const ADMIN_CREATE_COUPON = gql`
  mutation adminCreateCoupon($code: String!, $passType: MainType!) {
    adminCreateCoupon(code: $code, passType: $passType) {
      ok
      error
    }
  }
`;

const CreateCoupon = () => {
  const [adminCreateCouponMutation] = useMutation(ADMIN_CREATE_COUPON, {
    onCompleted: (d) => {
      if (d.adminCreateCoupon.ok) {
        onMoal();
      } else {
        alert(d.adminCreateCoupon.error);
      }
    },
  });
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    adminCreateCouponMutation({
      variables: {
        code,
        passType: data.passType,
      },
    });
  };
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => {
    setIsModal(false);
    history.push("/coupon");
  };
  const code = randomCode();
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut title="쿠폰번호 생성" btn1Value="저장하기" type1="submit">
        <InputForm title="쿠폰번호 정보">
          <CouponDropdown
            name="쿠폰 종류"
            register={register}
            formName="passType"
          />
          <InputItem
            name="쿠폰번호"
            value={code}
            register={register}
            formName="code"
            disable
          />
          <InputItem
            name="생성자"
            value="관리자"
            register={register}
            disable
            formName="creator"
          />
          <InputItem
            name="사용여부"
            value="사용전"
            register={register}
            formName="isUsed"
            disable
          />
          <InputItem
            name="사용자"
            value="사용되지 않은 쿠폰입니다."
            register={register}
            formName="usedUser"
            disable
          />
          <InputItem
            name="사용일"
            value="사용되지 않은 쿠폰입니다."
            register={register}
            formName="usedDate"
            disable
          />
        </InputForm>
        <Modal visible={isModal} body="저장되었습니다." confirm={close} />
      </LayOut>
    </form>
  );
};

export default CreateCoupon;
