export const UserColumns = {
  first: "상세보기",
  second: "닉네임",
  third: "이메일",
  fourth: "휴대전화",
  fifth: "가입일",
};

export const UserData = [
  {
    id: 1,
    nickname: "user1",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",

    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 2,
    nickname: "user2",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 3,
    nickname: "user3",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 4,
    nickname: "user4",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 5,
    nickname: "user5",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 6,
    nickname: "user6",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 7,
    nickname: "user7",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 8,
    nickname: "user8",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 9,
    nickname: "user9",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
  {
    id: 10,
    nickname: "user10",
    email: "minseok@naver.com",
    phone: "01012345678",
    createdAt: "2021.08.16",
    coupons: [
      {
        id: 1,
        code: "DJ23JK32LA",
        passType: "RECORDING",
      },
      {
        id: 2,
        code: "DJ23JK32LA",
        passType: "WRITING",
      },
    ],
  },
];
