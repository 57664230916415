import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import LayOut from "../../Components/LayOut";
import { useLocation, useHistory } from "react-router";
import Modal from "../../Components/Modal";
import BoardInfo from "../../Components/Board/BoardInfo";
import CommentList from "../../Components/Board/CommentList";

const ADMIN_GET_POST = gql`
  query adminGetPost($id: Int!) {
    adminGetPost(id: $id) {
      id
      type
      body
      createdAt
      user {
        username
      }
      page {
        title
      }
    }
  }
`;
const ADMIN_DELETE_POST = gql`
  mutation adminDeletePost($id: [Int]) {
    adminDeletePost(id: $id) {
      ok
      error
    }
  }
`;

const BoardDetail = () => {
  const location = useLocation();
  const {
    state: { id },
  } = location;
  const { data, loading } = useQuery(ADMIN_GET_POST, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();
  const [deleteModal, setDeleteModel] = useState(false);
  const onDeleteModal = () => setDeleteModel(true);
  const close = () => {
    setDeleteModel(false);
  };
  const [adminDeletePostMutation] = useMutation(ADMIN_DELETE_POST, {
    onCompleted: (d) => {
      if (d.adminDeletePost.ok) {
        close();
        history.push("/board");
      }
    },
  });
  return (
    <LayOut
      title="게시물 관리"
      btn1Value="게시물 삭제"
      red1
      btn1Click={onDeleteModal}
    >
      {!loading && <BoardInfo data={{ ...data?.adminGetPost }} />}
      <CommentList postId={id} />
      <Modal
        body="게시물 삭제 시, 작성된 내용과 하위 등록된 댓글도 모두 삭제되며 복구가 불가능합니다. 선택한 게시물을 삭제하시겠습니까?"
        visible={deleteModal}
        close={close}
        confirm={() => {
          adminDeletePostMutation({
            variables: {
              id: [id],
            },
          });
        }}
      />
    </LayOut>
  );
};

export default BoardDetail;
