import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { SearchIcon } from "../../Icons/Icons";
import fonts from "../../Styles/fonts";
import { getDate, reportConverter } from "../../utils";
import { CustomBtn } from "../Buttons";
import CheckBox from "../CheckBox";
import Modal from "../Modal";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
  position: relative;
`;

const Shell = styled.span`
  ${fonts.body1};
  :first-child {
    width: 10%;
  }
`;

const Btn = styled.button`
  cursor: pointer;
`;

const ReportSheetBody = ({
  id,
  type,
  user,
  body,
  createdAt,
  selected,
  toggleSelectPost,
  onDelete,
}) => {
  const history = useHistory();
  const location = useLocation();
  const goReportDetail = () => {
    history.push({
      pathname: "/reportDetail",
      state: {
        id: id,
        type,
        beforePath: location.pathname,
      },
    });
  };

  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <Container>
      <CheckBox
        marginRight="32px"
        checked={Boolean(
          selected.filter((report) => report === id).length !== 0
        )}
        onClick={() => toggleSelectPost(id)}
      />
      <Shell style={{ width: "10%" }}>
        <Btn
          type="button"
          onClick={() => {
            goReportDetail();
          }}
        >
          <SearchIcon />
        </Btn>
      </Shell>
      <Shell style={{ width: "15%" }}>{reportConverter(type)}</Shell>
      <Shell style={{ width: "35%" }}>{body}</Shell>
      <Shell style={{ width: "15%" }}>{user?.username}</Shell>
      <Shell style={{ width: "15%" }}>{getDate(parseInt(createdAt))}</Shell>
      <CustomBtn width="70px" value="삭제" red onClick={onMoal} />
      <Modal
        body="신고 삭제 시, 작성된 신고 내용이 삭제되며 복구가 불가능합니다. 선택한 신고를 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={() => onDelete({ variables: { id: [id] } })}
      />
    </Container>
  );
};
export default ReportSheetBody;
