import React from "react";
import styled from "styled-components";
import fonts from "../../Styles/fonts";
import { CustomBtn } from "../Buttons";

const Container = styled.div`
  width: 100%;
  padding: 12px 16px 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 32px;
  box-shadow: ${(p) => p.theme.shadow};
`;
const Title = styled.span`
  ${(p) => fonts.body1}
`;

const BtnWrapper = styled.div``;

const PageHeader = ({
  title,
  btnValue,
  red,
  btn2Value,
  red2,
  btn1Click,
  btn2Click,
  type1,
  type2,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <BtnWrapper>
        {btnValue && (
          <CustomBtn
            value={btnValue}
            red={red}
            onClick={btn1Click && btn1Click}
            type={type1}
          />
        )}
        {btn2Value && (
          <CustomBtn
            marginLeft="16px"
            value={btn2Value}
            red={red2}
            onClick={btn2Click && btn2Click}
            type={type2}
          />
        )}
      </BtnWrapper>
    </Container>
  );
};

export default PageHeader;
