import React from "react";
import styled from "styled-components";
import fonts from "../Styles/fonts";
import { CustomBtn } from "./Buttons";

const ModalWrapper = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;
  width: ${(p) => (p.width ? p.width : "480px")};
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 40px 20px 20px 20px;
`;

const BodyText = styled.span`
  display: block;
  ${fonts.body1}
  margin-bottom: 70px;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Modal = ({
  onClose,
  maskClosable,
  visible,
  body,
  width,
  close,
  confirm,
}) => {
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  return (
    <>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" width={width}>
          <BodyText>{body}</BodyText>
          <BtnWrapper>
            {close && (
              <CustomBtn value="취소" width="110px" outline onClick={close} />
            )}
            {confirm && (
              <CustomBtn
                value="확인"
                width="110px"
                marginLeft="16px"
                onClick={confirm}
              />
            )}
          </BtnWrapper>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

export default Modal;
