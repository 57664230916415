import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import LayOut from "../../Components/LayOut";
import { useHistory, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import ContentsInfo from "../../Components/Contents/ContentsInfo";
import Modal from "../../Components/Modal";
import { pathConverter } from "../../utils";

const SUBPART = gql`
  query subPart($id: Int!) {
    subPart(id: $id) {
      id
      title
      description
      num
      part {
        id
        num
        type
      }
    }
  }
`;
const ADMIN_DELETE_SUBPART = gql`
  mutation adminDeleteSubPart($id: [Int]) {
    adminDeleteSubPart(id: $id) {
      ok
      error
    }
  }
`;
const ADMIN_UPDATE_SUBPART = gql`
  mutation adminUpdateSubPart(
    $id: Int!
    $title: String
    $description: String
    $num: Int
    $partId: Int
  ) {
    adminUpdateSubPart(
      id: $id
      title: $title
      description: $description
      num: $num
      partId: $partId
    ) {
      ok
      error
    }
  }
`;

const ContentsDetail = () => {
  const location = useLocation();
  const {
    state: { id, type },
  } = location;
  const { data, loading } = useQuery(SUBPART, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    if (!data.partId || !data.num) {
      alert("파트 번호 혹은 콘텐츠 번호를 선택해 주세요.");
      return;
    }
    adminUpdateSubPartMutation({
      variables: {
        id,
        title: data.title,
        description: data.description,
        num: parseInt(data.num),
        partId: parseInt(data.partId),
      },
    });
  };
  const [saveModal, setSaveModal] = useState(false);
  const [deleteModal, setDeleteModel] = useState(false);
  const onSaveModal = () => setSaveModal(true);
  const onDeleteModal = () => setDeleteModel(true);
  const close = () => {
    setSaveModal(false);
    setDeleteModel(false);
  };
  const [adminDeleteSubPartMutation] = useMutation(ADMIN_DELETE_SUBPART, {
    onCompleted: (d) => {
      if (d.adminDeleteSubPart.ok) {
        close();
        history.push(pathConverter(type));
      }
    },
  });
  const [adminUpdateSubPartMutation] = useMutation(ADMIN_UPDATE_SUBPART, {
    onCompleted: (d) => {
      if (d.adminUpdateSubPart.ok) {
        onSaveModal();
      } else {
        alert(d.adminUpdateSubPart.error);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut
        title="콘텐츠 정보"
        btn1Value="저장하기"
        btn2Value="콘텐츠 삭제"
        type1="submit"
        btn2Click={onDeleteModal}
        red2
      >
        {!loading && (
          <ContentsInfo register={register} data={{ ...data?.subPart }} />
        )}
        <Modal
          visible={saveModal}
          body="저장되었습니다."
          confirm={() => history.push(pathConverter(type))}
        />
        <Modal
          body="콘텐츠 삭제 시, 관련 정보와 하위 등록된 활동들도 모두 삭제되며 복구가 불가능합니다. 선택한 콘텐츠를 삭제하시겠습니까?"
          visible={deleteModal}
          close={close}
          confirm={() => {
            adminDeleteSubPartMutation({
              variables: {
                id: [id],
              },
            });
          }}
        />
      </LayOut>
    </form>
  );
};

export default ContentsDetail;
