import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CenterSection = styled.div`
  display: flex;
  width: 300px;
  height: 26px;
  justify-content: space-between;
`;

const Pagenation = ({ total, onClickPage, page }) => {
  return (
    <Container>
      <CenterSection>
        {[...Array(total).keys()]
          .map((i) => i + 1)
          .map((item) => (
            <button
              style={{
                margin: "auto",
                cursor: "pointer",
                color: item === page && "blue",
              }}
              key={item}
              onClick={() => onClickPage(item)}
            >
              {item}
            </button>
          ))}
      </CenterSection>
    </Container>
  );
};
export default Pagenation;
