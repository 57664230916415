import React from "react";
import InputAreaItem from "../InputAreaItem";
import InputForm from "../InputForm";
import InputItem from "../InputItem";

const QnAInfo = ({ register, data }) => {
  const { title, body } = data;
  return (
    <InputForm title="자주 묻는 질문(Q&A) 정보">
      <InputItem
        name="게시글 제목"
        value={title}
        border
        width="50%"
        register={register}
        formName="title"
      />

      <InputAreaItem
        name="게시글 내용"
        value={body}
        border
        width="100%"
        height="110px"
        register={register}
        formName="body"
      />
    </InputForm>
  );
};

export default QnAInfo;
