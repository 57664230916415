import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import PartDropdown from "../../Components/Dropdown/PartDropdown";
import SubPartDropdown from "../../Components/Dropdown/SubPartDropdown";
import InputAreaItem from "../../Components/InputAreaItem";
import InputForm from "../../Components/InputForm";
import InputItem from "../../Components/InputItem";
import InputItemPhoto from "../../Components/InputItemPhoto";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";

const ADMIN_CREATE_PAGE = gql`
  mutation adminCreatePage(
    $subPartId: Int!
    $num: Int!
    $title: String!
    $description: String!
    $thumbnail: Upload!
    $type: MainType!
  ) {
    adminCreatePage(
      subPartId: $subPartId
      num: $num
      title: $title
      thumbnail: $thumbnail
      description: $description
      type: $type
    ) {
      ok
      error
    }
  }
`;

const CreateActive = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    state: { type, beforePath },
  } = location;
  const { register, handleSubmit, watch, setValue } = useForm({
    mode: "onChange",
  });
  const [adminCreatePageMutation] = useMutation(ADMIN_CREATE_PAGE, {
    onCompleted: (d) => {
      if (d.adminCreatePage.ok) {
        onMoal();
      } else {
        alert(d.adminCreatePage.error);
      }
    },
  });
  const onValid = (data) => {
    if (
      !(data.subPartId !== "콘텐츠 번호 선택") ||
      !data.num ||
      !data.title ||
      !Boolean(data.thumbnail[0]) ||
      !data.description ||
      !type
    ) {
      alert("모든 항목을 입력해주세요.");
      return;
    }
    adminCreatePageMutation({
      variables: {
        subPartId: parseInt(data.subPartId),
        num: parseInt(data.num),
        title: data.title,
        thumbnail: data.thumbnail[0],
        description: data.description,
        type,
      },
    });
  };
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => {
    setIsModal(false);
    history.push(beforePath);
  };
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut title="활동 생성" btn1Value="저장하기" type1="submit">
        <InputForm title="활동 정보">
          <PartDropdown
            name="파트 번호"
            value={undefined}
            register={register}
            formName="partId"
            type={type}
          />
          <SubPartDropdown
            name="콘텐츠 번호"
            register={register}
            formName="subPartId"
            partId={watch("partId") ?? undefined}
            type={type}
            value={undefined}
            reset={() => setValue("subPartId", undefined)}
          />
          <InputItem
            name="활동 번호"
            placeholder="활동 번호를 입력해주세요. (숫자만 가능)"
            border
            register={register}
            formName="num"
            type="number"
          />
          <InputItem
            name="활동 제목"
            placeholder="활동 제목를 입력해주세요."
            border
            register={register}
            formName="title"
          />
          <InputItemPhoto
            name="활동 이미지"
            placeholder="파일 이름:"
            register={register}
            formName="thumbnail"
            value={undefined}
          />
          <InputAreaItem
            name="활동 설명"
            placeholder="활동 설명을 입력해주세요."
            border
            width="100%"
            height="110px"
            register={register}
            formName="description"
          />
        </InputForm>
        <Modal visible={isModal} body="저장되었습니다." confirm={close} />
      </LayOut>
    </form>
  );
};

export default CreateActive;
