export const ReportColumns = {
  first: "상세보기",
  second: "신고 유형",
  third: "신고 내용",
  fourth: "신고자",
  fifth: "신고일자",
};

export const ReportData = [
  {
    id: 1,
    type: "댓글",
    body: "싸움 유발하는 글입니다.",
    user: "사용자1",
    createdAt: "21.08.23",
  },
  {
    id: 2,
    type: "게시글",
    body: "낚시를 유도합니다.",
    user: "사용자2",
    createdAt: "21.08.23",
  },
  {
    id: 3,
    type: "게시글",
    body: "신고 테스트",
    user: "사용자3",
    createdAt: "21.08.23",
  },
  {
    id: 4,
    type: "댓글",
    body: "의도적 도배 게시글",
    user: "사용자4",
    createdAt: "21.08.23",
  },
  {
    id: 5,
    type: "댓글",
    body: "신고 테스트",
    user: "사용자4",
    createdAt: "21.08.23",
  },
  {
    id: 6,
    type: "댓글",
    body: "신고 테스트",
    user: "사용자3",
    createdAt: "21.08.23",
  },
  {
    id: 7,
    type: "댓글",
    body: "신고 테스트",
    user: "사용자3",
    createdAt: "21.08.23",
  },
  {
    id: 8,
    type: "댓글",
    body: "신고 테스트",
    user: "사용자2",
    createdAt: "21.08.23",
  },
  {
    id: 9,
    type: "댓글",
    body: "신고 테스트",
    user: "사용자1",
    createdAt: "21.08.23",
  },
  {
    id: 10,
    type: "댓글",
    body: "신고 테스트",
    user: "사용자1",
    createdAt: "21.08.23",
  },
];
