export const QnAColumns = {
  first: "상세보기",
  second: "글제목",
};

export const PartColumns = {
  first: "상세보기",
  second: "카테고리 이름",
  third: "파트 번호",
};

export const QnAData = [
  {
    id: 1,
    title: "떠나찾은 어떤 앱인가요?",
  },
  {
    id: 2,
    title: "이용권은 어떻게 결제하나요?",
  },
  {
    id: 3,
    title: "쿠폰 번호는 어떻게 등록하나요?",
  },
  {
    id: 4,
    title: "외국인·해외 거주자도 회원가입할 수 있나요?",
  },
  {
    id: 5,
    title: "개인정보는 안전하게 보호되나요?",
  },
  {
    id: 6,
    title: "Q&A Test",
  },
  {
    id: 7,
    title: "Q&A Test",
  },
  {
    id: 8,
    title: "Q&A Test",
  },
  {
    id: 9,
    title: "Q&A Test",
  },
  {
    id: 10,
    title: "Q&A Test",
  },
];
