import React from "react";
import styled from "styled-components";
import fonts from "../../Styles/fonts";
import { getExactDate, paymentConverter } from "../../utils";
// import { CustomBtn } from "../Buttons";
// import CheckBox from "../CheckBox";
// import Modal from "../Modal";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
  position: relative;
`;

const Shell = styled.span`
  ${fonts.body1};
  :first-child {
    width: 10%;
  }
`;

const PaymentSheetBody = ({ createdAt, user, type }) => {
  // const [isModal, setIsModal] = useState(false);
  // const onMoal = () => setIsModal(true);
  // const close = () => setIsModal(false);
  return (
    <Container>
      {/* <CheckBox marginRight="32px" /> */}
      <Shell style={{ width: "30%", marginLeft: "48px" }}>
        {getExactDate(parseInt(createdAt))}
      </Shell>
      <Shell style={{ width: "30%" }}>{paymentConverter(type)}</Shell>
      <Shell style={{ width: "30%" }}>{user?.username ?? "탈퇴 회원"}</Shell>

      {/* <CustomBtn width="70px" value="삭제" red onClick={onMoal} />
      <Modal
        body="결제 내역 삭제 시, 삭제된 결제 내역은 복구가 불가능합니다. 선택한 결제 내역을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={close}
      /> */}
    </Container>
  );
};
export default PaymentSheetBody;
