import React, { useState } from "react";
import styled from "styled-components";
import MenuBox from "../Components/MenuBox";
import { MenuListData } from "../Data/MenuListData";

const Container = styled.div`
  width: 280px;
  height: 100%;
`;

const SideMenu = () => {
  const [isFocused, setIsFocused] = useState({
    user: true,
    contents: false,
    board: false,
    site: false,
    payment: false,
  });

  const onClickFocus = (name) => {
    setIsFocused({
      ...{
        user: false,
        contents: false,
        board: false,
        site: false,
        payment: false,
      },
      ...{ [name]: !isFocused[name] },
    });
  };

  return (
    <Container>
      {MenuListData.map((item) => (
        <MenuBox
          {...item}
          key={item.id}
          isFocused={isFocused[item.name]}
          onClickFocus={onClickFocus}
        />
      ))}
    </Container>
  );
};

export default SideMenu;
