import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";

import styled from "styled-components";
import { SearchIcon } from "../../Icons/Icons";
import fonts from "../../Styles/fonts";
import { getDate } from "../../utils";
import { CustomBtn } from "../Buttons";
import CheckBox from "../CheckBox";
import Modal from "../Modal";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
  position: relative;
`;

const Shell = styled.span`
  ${fonts.body1};
  :first-child {
    width: 10%;
  }
`;

const Btn = styled.button`
  cursor: pointer;
`;

const UserSheetBody = ({
  id,
  username,
  email,
  phone,
  createdAt,
  selected,
  toggleSelectUser,
  onDelete,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  const goUserDetail = () => {
    history.push({
      pathname: "/userDetail",
      state: {
        id,
        beforePath: location.pathname,
      },
    });
  };
  return (
    <Container>
      <CheckBox
        marginRight="32px"
        checked={Boolean(selected.filter((user) => user === id).length !== 0)}
        onClick={() => toggleSelectUser(id)}
      />
      <Shell style={{ width: "10%" }}>
        <Btn type="button" onClick={() => goUserDetail()}>
          <SearchIcon />
        </Btn>
      </Shell>
      <Shell style={{ width: "20%" }}>{username}</Shell>
      <Shell style={{ width: "20%" }}>{email}</Shell>
      <Shell style={{ width: "20%" }}>{phone}</Shell>
      <Shell style={{ width: "20%" }}>{getDate(createdAt)}</Shell>
      <CustomBtn width="70px" value="삭제" red onClick={onMoal} />
      <Modal
        body="회원 삭제 시, 관련 정보의 복구가 불가능합니다. 선택한 회원을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={() => onDelete({ variables: { id: [id] } })}
      />
    </Container>
  );
};
export default UserSheetBody;
