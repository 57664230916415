import React from "react";
import PartDropdown from "../Dropdown/PartDropdown";
import InputAreaItem from "../InputAreaItem";
import InputForm from "../InputForm";
import InputItem from "../InputItem";

const ContentsInfo = ({ register, data }) => {
  const { part, num, title, description } = data;
  return (
    <InputForm title="회원 정보">
      <PartDropdown
        name="파트 번호"
        register={register}
        formName="partId"
        type={part?.type}
        value={part?.id}
      />
      <InputItem
        name="콘텐츠 번호"
        value={num}
        border
        register={register}
        formName="num"
        type="number"
      />
      <InputItem
        name="콘텐츠 제목"
        value={title}
        register={register}
        formName="title"
      />

      <InputAreaItem
        name="콘텐츠 설명"
        value={description}
        border
        width="100%"
        height="110px"
        register={register}
        formName="description"
      />
    </InputForm>
  );
};

export default ContentsInfo;
