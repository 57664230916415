import React from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { SearchIcon } from "../../Icons/Icons";
import fonts from "../../Styles/fonts";
import { partConverter } from "../../utils";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
  position: relative;
`;

const Shell = styled.span`
  ${fonts.body1};
  :first-child {
    width: 10%;
  }
`;

const Btn = styled.button`
  cursor: pointer;
  margin-left: 32px;
`;

const PartSheetBody = ({ id, type, num }) => {
  const history = useHistory();
  const location = useLocation();
  const goPartDetail = () => {
    history.push({
      pathname: "/partDetail",
      state: {
        id: id,
        beforePath: location.pathname,
      },
    });
  };
  return (
    <Container>
      <Shell style={{ width: "13%" }}>
        <Btn
          type="button"
          onClick={() => {
            goPartDetail();
          }}
        >
          <SearchIcon />
        </Btn>
      </Shell>
      <Shell style={{ width: "40%" }}>{partConverter(type)}</Shell>
      <Shell style={{ width: "47%" }}>{num}</Shell>
    </Container>
  );
};
export default PartSheetBody;
