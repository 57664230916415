import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import BoardSheetBody from "../../Components/Sheet/BoardSheetBody";
import BoardSheetHeader from "../../Components/Sheet/BoardSheetHeader";
import Pagenation from "../../Components/Sheet/Pagenation";
import { BoardColumns } from "../../Data/board/Board";
import { SearchBoardList } from "../../Data/DropdownData";
import SearchDropdown from "../../Components/Dropdown/SearchDropdown";

const Container = styled.div`
  height: 615px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const ADMIN_GET_POSTS = gql`
  query adminGetPosts($page: Int, $keyword: String, $type: MainType) {
    adminGetPosts(page: $page, keyword: $keyword, type: $type) {
      ok
      error
      posts {
        id
        page {
          title
        }
        type
        user {
          username
        }
      }
      totalPages
    }
  }
`;
const ADMIN_DELETE_POST = gql`
  mutation adminDeletePost($id: [Int]) {
    adminDeletePost(id: $id) {
      ok
      error
    }
  }
`;

const Board = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState(undefined);
  const [keyword, setKeyword] = useState(undefined);
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  const [selected, setSelected] = useState([]);
  const toggleSelectPost = (id) => {
    if (selected.filter((post) => post === id).length !== 0) {
      setSelected(selected.filter((post) => post !== id));
    } else {
      setSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const selectAll = () => {
    if (selected.length < data?.adminGetPosts?.posts?.length) {
      const prevArray = [];
      data?.adminGetPosts?.posts?.map((post) => {
        if (selected.filter((id) => post === id).length === 0) {
          prevArray.push(post.id);
        }
        return null;
      });
      setSelected(prevArray);
    } else {
      setSelected([]);
    }
  };
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading, refetch } = useQuery(ADMIN_GET_POSTS, {
    variables: { page, keyword, type: type === "" ? undefined : type },
    onCompleted: (d) => {
      if (d.adminGetPosts.ok) {
        setTotalPages(d.adminGetPosts.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setSelected([]);
    setPage(page);
  };
  const [adminDeletePostMutation, { loading: mutationLoading }] = useMutation(
    ADMIN_DELETE_POST,
    {
      onCompleted: (d) => {
        setPage(1);
        close();
        refetch();
      },
    }
  );
  const onDeleteAll = () => {
    if (!mutationLoading) {
      adminDeletePostMutation({
        variables: { id: selected },
        onCompleted: (d) => {
          setPage(1);
          close();
          refetch();
        },
      });
    }
  };
  return (
    <LayOut title="게시물 관리" btn1Value="선택 삭제" red1 btn1Click={onMoal}>
      <Search value={keyword} onChangeValue={onChangeKeyword}>
        <SearchDropdown
          data={SearchBoardList}
          defaultName="전체 게시판"
          value={type}
          setValue={setType}
          reset={() => null}
        />
      </Search>
      <Container>
        {!loading && (
          <BoardSheetHeader
            UserColumns={BoardColumns}
            checked={selected.length === data?.adminGetPosts?.posts?.length}
            onClick={selectAll}
          />
        )}
        {!loading &&
          data?.adminGetPosts?.posts?.map((item) => (
            <BoardSheetBody
              {...item}
              key={item.id}
              selected={selected}
              toggleSelectPost={toggleSelectPost}
              onDelete={adminDeletePostMutation}
            />
          ))}
      </Container>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      <Modal
        body="게시물 삭제 시, 작성된 내용과 하위 등록된 댓글도 모두 삭제되며 복구가 불가능합니다. 선택한 게시물을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={onDeleteAll}
      />
    </LayOut>
  );
};
export default Board;
