import React from "react";
import styled from "styled-components";
import fonts from "../../Styles/fonts";
import CheckBox from "../CheckBox";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
`;

const Shell = styled.span`
  ${fonts.body1};
  color: ${(p) => p.theme.menuTitle};
  :first-child {
    width: 10%;
  }
`;

const ContentsSheetHeader = ({ Columns, ActiveColumns, checked, onClick }) => {
  return (
    <Container>
      <CheckBox marginRight="32px" checked={checked} onClick={onClick} />
      {Columns && (
        <>
          <Shell style={{ width: "10%" }}>{Columns.first}</Shell>
          <Shell style={{ width: "20%" }}>{Columns.second}</Shell>
          <Shell style={{ width: "20%" }}>{Columns.third}</Shell>
          <Shell style={{ width: "40%" }}>{Columns.fourth}</Shell>
        </>
      )}
      {ActiveColumns && (
        <>
          <Shell style={{ width: "10%" }}>{ActiveColumns.first}</Shell>
          <Shell style={{ width: "15%" }}>{ActiveColumns.second}</Shell>
          <Shell style={{ width: "15%" }}>{ActiveColumns.third}</Shell>
          <Shell style={{ width: "15%" }}>{ActiveColumns.fourth}</Shell>
          <Shell style={{ width: "35%" }}>{ActiveColumns.fifth}</Shell>
        </>
      )}
    </Container>
  );
};
export default ContentsSheetHeader;
