import React from "react";
import { partConverter } from "../../utils";
import InputForm from "../InputForm";
import InputItem from "../InputItem";
import InputItemPhoto from "../InputItemPhoto";

const PartInfo = ({ register, data }) => {
  const { type, num, thumbnail } = data;
  return (
    <InputForm title="파트 정보">
      <InputItem
        name="카테고리 이름"
        value={partConverter(type)}
        register={register}
        formName="type"
        disable
      />
      <InputItem
        name="파트 번호"
        value={num}
        register={register}
        formName="num"
        disable
      />
      <InputItemPhoto
        name="활동 이미지"
        register={register}
        formName="thumbnail"
        value={thumbnail}
      />
    </InputForm>
  );
};

export default PartInfo;
