import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import LayOut from "../../Components/LayOut";
import { useHistory, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import Modal from "../../Components/Modal";
import CouponInfo from "../../Components/Site/CouponInfo";

const COUPON = gql`
  query coupon($id: Int!) {
    coupon(id: $id) {
      id
      passType
      code
      creator {
        id
        username
      }
      isUsed
      usedUser {
        id
        username
      }
      usedDate
    }
  }
`;
const ADMIN_DELETE_COUPON = gql`
  mutation adminDeleteCoupon($id: [Int]) {
    adminDeleteCoupon(id: $id) {
      ok
      error
    }
  }
`;
const ADMIN_UPDATE_COUPON = gql`
  mutation adminUpdateCoupon($id: Int!, $passType: MainType) {
    adminUpdateCoupon(id: $id, passType: $passType) {
      ok
      error
    }
  }
`;

const CouponDetail = () => {
  const location = useLocation();
  const {
    state: { id },
  } = location;
  const { data, loading } = useQuery(COUPON, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    adminUpdateCouponMutation({
      variables: {
        id,
        passType: data.passType,
      },
    });
  };
  const [saveModal, setSaveModal] = useState(false);
  const [deleteModal, setDeleteModel] = useState(false);
  const onSaveModal = () => setSaveModal(true);
  const onDeleteModal = () => setDeleteModel(true);
  const close = () => {
    setSaveModal(false);
    setDeleteModel(false);
  };
  const [adminDeleteCouponMutation] = useMutation(ADMIN_DELETE_COUPON, {
    onCompleted: (d) => {
      if (d.adminDeleteCoupon.ok) {
        close();
        history.push("/coupon");
      }
    },
  });
  const [adminUpdateCouponMutation] = useMutation(ADMIN_UPDATE_COUPON, {
    onCompleted: (d) => {
      if (d.adminUpdateCoupon.ok) {
        onSaveModal();
      } else {
        alert(d.adminUpdateCoupon.error);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut
        title="쿠폰번호 관리"
        btn1Value="저장하기"
        btn2Value="쿠폰 삭제"
        type1="submit"
        btn2Click={onDeleteModal}
        red2
      >
        {!loading && (
          <CouponInfo register={register} data={{ ...data?.coupon }} />
        )}
        <Modal
          visible={saveModal}
          body="저장되었습니다."
          confirm={() => history.push("/coupon")}
        />
        <Modal
          body="쿠폰 삭제 시, 삭제된 쿠폰번호는 다시 사용할 수 없으며 복구가 불가능합니다. 선택한 쿠폰을 삭제하시겠습니까?"
          visible={deleteModal}
          close={close}
          confirm={() => {
            adminDeleteCouponMutation({
              variables: {
                id: [id],
              },
            });
          }}
        />
      </LayOut>
    </form>
  );
};

export default CouponDetail;
