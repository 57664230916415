import React from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import { PartColumns } from "../../Data/site/QnA";
import PartSheetHeader from "../../Components/Sheet/PartSheetHeader";
import PartSheetBody from "../../Components/Sheet/PartSheetBody";

const Container = styled.div`
  height: 615px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const ADMIN_GET_PARTS = gql`
  query adminGetParts($type: MainType) {
    adminGetParts(type: $type) {
      id
      type
      num
    }
  }
`;

const Part = () => {
  const { data, loading } = useQuery(ADMIN_GET_PARTS, {
    variables: { type: undefined },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  return (
    <LayOut title="파트 이미지 관리">
      <Container>
        <PartSheetHeader PartColumns={PartColumns} />
        {!loading &&
          data?.adminGetParts?.map((item) => (
            <PartSheetBody {...item} key={item.id} />
          ))}
      </Container>
    </LayOut>
  );
};
export default Part;
