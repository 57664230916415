export const CommentColumns = {
  first: "댓글 내용",
  second: "작성자",
  third: "작성일",
};

export const CommentData = [
  {
    id: 1,
    body: "테스트 댓글입니다.",
    user: "사용자1",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 2,
    body: "테스트 댓글입니다.",
    user: "사용자2",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 3,
    body: "테스트 댓글입니다.",
    user: "사용자3",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 4,
    body: "테스트 댓글입니다.",
    user: "사용자4",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 5,
    body: "테스트 댓글입니다.",
    user: "사용자1",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 6,
    body: "테스트 댓글입니다.",
    user: "사용자1",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 7,
    body: "테스트 댓글입니다.",
    user: "사용자1",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 8,
    body: "테스트 댓글입니다.",
    user: "사용자1",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 9,
    body: "테스트 댓글입니다.",
    user: "사용자1",
    createdAt: "2021.08.23 12:32:23",
  },
  {
    id: 10,
    body: "테스트 댓글입니다.",
    user: "사용자1",
    createdAt: "2021.08.23 12:32:23",
  },
];
