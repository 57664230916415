import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { SearchIcon } from "../../Icons/Icons";
import fonts from "../../Styles/fonts";
import { CustomBtn } from "../Buttons";
import CheckBox from "../CheckBox";
import Modal from "../Modal";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
  position: relative;
`;

const Shell = styled.span`
  ${fonts.body1};
  :first-child {
    width: 10%;
  }
`;

const Btn = styled.button`
  cursor: pointer;
`;

const NoticeSheetBody = ({
  id,
  title,
  selected,
  toggleSelectNotice,
  onDelete,
}) => {
  const history = useHistory();
  const location = useLocation();
  const goNoticeDetail = () => {
    history.push({
      pathname: "/noticeDetail",
      state: {
        id: id,
        beforePath: location.pathname,
      },
    });
  };

  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <Container>
      <CheckBox
        marginRight="32px"
        checked={Boolean(
          selected.filter((notice) => notice === id).length !== 0
        )}
        onClick={() => toggleSelectNotice(id)}
      />
      <Shell style={{ width: "10%" }}>
        <Btn
          type="button"
          onClick={() => {
            goNoticeDetail();
          }}
        >
          <SearchIcon />
        </Btn>
      </Shell>
      <Shell style={{ width: "80%" }}>{title}</Shell>
      <CustomBtn width="70px" value="삭제" red onClick={onMoal} />
      <Modal
        body="공지사항 삭제 시, 작성된 공지사항 내용이 삭제되며 복구가 불가능합니다. 선택한 공지사항을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={() => onDelete({ variables: { id: [id] } })}
      />
    </Container>
  );
};
export default NoticeSheetBody;
