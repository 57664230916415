import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import { CommentColumns } from "../../Data/board/Comment";
import fonts from "../../Styles/fonts";
import CommentSheetBody from "../Sheet/CommentSheetBody";
import CommentSheetHeader from "../Sheet/CommentSheetHeader";
import Pagenation from "../Sheet/Pagenation";

const Container = styled.div``;

const Title = styled.span`
  ${fonts.subtitle1}
  padding-left: 8px;
  margin-bottom: 24px;
  display: block;
`;

const SheetWrapper = styled.div`
  height: fit-content;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const ADMIN_GET_COMMENTS = gql`
  query adminGetComments($postId: Int!, $page: Int) {
    adminGetComments(postId: $postId, page: $page) {
      ok
      error
      comments {
        id
        body
        user {
          username
        }
        createdAt
      }
      totalPages
    }
  }
`;

const ADMIN_DELETE_COMMENT = gql`
  mutation adminDeleteComment($id: [Int]) {
    adminDeleteComment(id: $id) {
      ok
      error
    }
  }
`;

const CommentList = ({ postId }) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { data, loading, refetch } = useQuery(ADMIN_GET_COMMENTS, {
    variables: { page, postId },
    onCompleted: (d) => {
      if (d.adminGetComments.ok) {
        setTotalPages(d.adminGetComments.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setPage(page);
  };
  const [adminDeleteCommentMutation] = useMutation(ADMIN_DELETE_COMMENT, {
    onCompleted: (d) => {
      setPage(1);
      refetch();
    },
  });
  return (
    <Container>
      <Title>댓글 정보</Title>
      <SheetWrapper>
        <CommentSheetHeader UserColumns={CommentColumns} />
        {!loading &&
          data?.adminGetComments?.comments?.map((item) => (
            <CommentSheetBody
              {...item}
              key={item.id}
              onDelete={adminDeleteCommentMutation}
            />
          ))}
      </SheetWrapper>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
    </Container>
  );
};

export default CommentList;
