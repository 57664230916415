export const ContentsColumns = {
  first: "상세보기",
  second: "파트 번호",
  third: "콘텐츠 번호",
  fourth: "콘텐츠 제목",
};

export const ActiveColumns = {
  first: "상세보기",
  second: "파트 번호",
  third: "콘텐츠 번호",
  fourth: "활동 번호",
  fifth: "활동 제목",
};

export const ContentsData = [
  {
    id: 1,
    partNum: 1,
    contentsNum: 1,
    title: "[예시] 파트 1번의 콘텐츠 1번입니다.",
    type: "RECORDING",
  },
  {
    id: 2,
    partNum: 1,
    contentsNum: 2,
    title: "[예시] 파트 1번의 콘텐츠 2번입니다.",
    type: "RECORDING",
  },
  {
    id: 3,
    partNum: 1,
    contentsNum: 3,
    title: "[예시] 파트 1번의 콘텐츠 3번입니다.",
    type: "RECORDING",
  },
  {
    id: 4,
    partNum: 2,
    contentsNum: 1,
    title: "[예시] 파트 2번의 콘텐츠 1번입니다.",
    type: "RECORDING",
  },
  {
    id: 5,
    partNum: 2,
    contentsNum: 1,
    title: "[예시] 파트 2번의 콘텐츠 1번입니다.",
    type: "RECORDING",
  },
  {
    id: 6,
    partNum: 3,
    contentsNum: 2,
    title: "[예시] 파트 3번의 콘텐츠 2번입니다.",
    type: "RECORDING",
  },
  {
    id: 7,
    partNum: 3,
    contentsNum: 2,
    title: "[예시] 파트 3번의 콘텐츠 2번입니다.",
    type: "RECORDING",
  },
  {
    id: 8,
    partNum: 4,
    contentsNum: 3,
    title: "[예시] 파트 4번의 콘텐츠 3번입니다.",
    type: "RECORDING",
  },
  {
    id: 9,
    partNum: 4,
    contentsNum: 1,
    title: "[예시] 파트 4번의 콘텐츠 1번입니다.",
    type: "RECORDING",
  },
  {
    id: 10,
    partNum: 4,
    contentsNum: 1,
    title: "[예시] 파트 4번의 콘텐츠 1번입니다.",
    type: "RECORDING",
  },
  {
    id: 11,
    partNum: 1,
    contentsNum: 1,
    title: "드로잉 테스트",
    type: "DRAWING",
  },
  {
    id: 12,
    partNum: 1,
    contentsNum: 2,
    title: "드로잉 테스트",
    type: "DRAWING",
  },
  {
    id: 13,
    partNum: 1,
    contentsNum: 3,
    title: "드로잉 테스트",
    type: "DRAWING",
  },
  {
    id: 14,
    partNum: 2,
    contentsNum: 1,
    title: "드로잉 테스트",
    type: "DRAWING",
  },
  {
    id: 15,
    partNum: 2,
    contentsNum: 1,
    title: "드로잉 테스트",
    type: "DRAWING",
  },
  {
    id: 16,
    partNum: 3,
    contentsNum: 2,
    title: "드로잉 테스트",
    type: "DRAWING",
  },
  {
    id: 17,
    partNum: 3,
    contentsNum: 2,
    title: "드로잉 테스트",
    type: "DRAWING",
  },
  {
    id: 18,
    partNum: 4,
    contentsNum: 3,
    title: "[예시] 파트 4번의 콘텐츠 3번입니다.",
    type: "DRAWING",
  },
  {
    id: 19,
    partNum: 4,
    contentsNum: 1,
    title: "라이팅 테스트",
    type: "WRITING",
  },
  {
    id: 20,
    partNum: 4,
    contentsNum: 1,
    title: "라이팅 테스트",
    type: "WRITING",
  },
];

export const ActiveData = [
  {
    id: 1,
    partNum: 1,
    contentsNum: 1,
    activeNum: 1,
    title: "레코딩 활동",
    type: "RECORDING",
  },
  {
    id: 2,
    partNum: 1,
    contentsNum: 2,
    activeNum: 1,
    title: "레코딩 활동",
    type: "RECORDING",
  },
  {
    id: 3,
    partNum: 1,
    contentsNum: 3,
    activeNum: 1,
    title: "레코딩 활동",
    type: "RECORDING",
  },
  {
    id: 4,
    partNum: 2,
    contentsNum: 1,
    activeNum: 1,
    title: "레코딩 활동",
    type: "RECORDING",
  },
  {
    id: 5,
    partNum: 2,
    contentsNum: 1,
    activeNum: 1,
    title: "레코딩 활동",
    type: "RECORDING",
  },
  {
    id: 6,
    partNum: 3,
    contentsNum: 2,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 7,
    partNum: 3,
    contentsNum: 2,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 8,
    partNum: 4,
    contentsNum: 3,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 9,
    partNum: 4,
    contentsNum: 1,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 10,
    partNum: 4,
    contentsNum: 1,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 11,
    partNum: 1,
    contentsNum: 1,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 12,
    partNum: 1,
    contentsNum: 2,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 13,
    partNum: 1,
    contentsNum: 3,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 14,
    partNum: 2,
    contentsNum: 1,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 15,
    partNum: 2,
    contentsNum: 1,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 16,
    partNum: 3,
    contentsNum: 2,
    activeNum: 1,
    title: "드로잉 활동",
    type: "DRAWING",
  },
  {
    id: 17,
    partNum: 3,
    contentsNum: 2,
    activeNum: 1,
    title: "드로잉 테스트",
    type: "DRAWING",
  },
  {
    id: 18,
    partNum: 4,
    contentsNum: 3,
    activeNum: 1,
    title: "라이팅 활동",
    type: "WRITING",
  },
  {
    id: 19,
    partNum: 4,
    contentsNum: 1,
    activeNum: 1,
    title: "라이팅 활동",
    type: "WRITING",
  },
  {
    id: 20,
    partNum: 4,
    contentsNum: 1,
    activeNum: 1,
    title: "라이팅 활동",
    type: "WRITING",
  },
];
