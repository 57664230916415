import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import Pagenation from "../../Components/Sheet/Pagenation";
import { QnAColumns } from "../../Data/site/QnA";
import QnASheetHeader from "../../Components/Sheet/QnASheetHeader";
import QnASheetBody from "../../Components/Sheet/QnASheetBody";

const Container = styled.div`
  height: 615px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const FAQS = gql`
  query faqs($page: Int, $keyword: String) {
    faqs(page: $page, keyword: $keyword) {
      ok
      error
      faqs {
        id
        title
      }
      totalPages
    }
  }
`;

const ADMIN_DELETE_FAQ = gql`
  mutation adminDeleteFAQ($id: [Int]) {
    adminDeleteFAQ(id: $id) {
      ok
      error
    }
  }
`;

const QnA = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(undefined);
  const history = useHistory();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const toggleSelectFaq = (id) => {
    if (selected.filter((faq) => faq === id).length !== 0) {
      setSelected(selected.filter((faq) => faq !== id));
    } else {
      setSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const selectAll = () => {
    if (selected.length < data?.faqs?.faqs?.length) {
      const prevArray = [];
      data?.faqs?.faqs?.map((faq) => {
        if (selected.filter((id) => faq === id).length === 0) {
          prevArray.push(faq.id);
        }
        return null;
      });
      setSelected(prevArray);
    } else {
      setSelected([]);
    }
  };
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading, refetch } = useQuery(FAQS, {
    variables: { page, keyword },
    onCompleted: (d) => {
      if (d.faqs.ok) {
        setTotalPages(d.faqs.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setSelected([]);
    setPage(page);
  };
  const [adminDeleteFaqMutation, { loading: mutationLoading }] = useMutation(
    ADMIN_DELETE_FAQ,
    {
      onCompleted: (d) => {
        setPage(1);
        close();
        refetch();
      },
    }
  );
  const onDeleteAll = () => {
    if (!mutationLoading) {
      adminDeleteFaqMutation({
        variables: { id: selected },
        onCompleted: (d) => {
          setPage(1);
          close();
          refetch();
        },
      });
    }
  };
  const goCreateQnA = () =>
    history.push("/createQnA", { beforePath: location.pathname });
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <LayOut
      title="자주 묻는 질문(Q&A) 관리"
      btn1Value="Q&A 생성"
      btn1Click={goCreateQnA}
      btn2Value="선택 삭제"
      red2
      btn2Click={onMoal}
    >
      <Search value={keyword} onChangeValue={onChangeKeyword} />
      <Container>
        {!loading && (
          <QnASheetHeader
            FaqColumns={QnAColumns}
            checked={selected.length === data?.faqs?.faqs?.length}
            onClick={selectAll}
          />
        )}
        {!loading &&
          data?.faqs?.faqs?.map((item) => (
            <QnASheetBody
              {...item}
              key={item.id}
              selected={selected}
              toggleSelectFaq={toggleSelectFaq}
              onDelete={adminDeleteFaqMutation}
            />
          ))}
      </Container>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      <Modal
        body="Q&A 삭제 시, 작성된 Q&A 내용이 삭제되며 복구가 불가능합니다. 선택한 Q&A를 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={onDeleteAll}
      />
    </LayOut>
  );
};
export default QnA;
