import React from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import fonts from "../../Styles/fonts";

const Contianer = styled.div`
  width: ${(p) => (p.width ? p.width : "310px")};
  position: relative;
  border-bottom: ${(p) => (p.border ? "none" : "1px solid black")};
  margin-right: 36px;
  margin-bottom: 24px;
  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
`;
const Name = styled.span`
  ${fonts.body2}
  display: block;
  margin-bottom: 16px;
`;
const Select = styled.select`
  ${fonts.body2}
  padding-top: 8px;
  width: 100%;
  border: none;
  outline: none;
`;
const Option = styled.option`
  width: 100%;
  text-align: center;
  :first-child {
    text-align: start;
  }
`;

const ADMIN_GET_PARTS = gql`
  query adminGetParts($type: MainType) {
    adminGetParts(type: $type) {
      id
      num
    }
  }
`;

const PartDropdown = ({ width, name, register, formName, type, value }) => {
  const { data, loading } = useQuery(ADMIN_GET_PARTS, {
    variables: {
      type,
    },
  });
  return (
    <>
      <Contianer width={width}>
        <Name>{name}</Name>
        {!loading && (
          <Select
            {...(register && { ...register(formName, { require: true }) })}
            defaultValue={value}
          >
            <Option value={undefined} hidden>
              파트 번호 선택
            </Option>
            {data?.adminGetParts?.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.num}
              </Option>
            ))}
          </Select>
        )}
      </Contianer>
    </>
  );
};

export default PartDropdown;
