import { ApolloClient, InMemoryCache, makeVar } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

const TOKEN = "admin-token";
const DARK_MODE = "DARK_MODE";
// export const isLoggedInVar = makeVar(Boolean(localStorage.getItem(TOKEN)));
export const isLoggedInVar = makeVar(Boolean(sessionStorage.getItem(TOKEN)));

export const logUserIn = (token) => {
  sessionStorage.setItem(TOKEN, token);
  isLoggedInVar(true);
};

export const logUserOut = () => {
  sessionStorage.removeItem(TOKEN);
  isLoggedInVar(false);
};

export const darkModeVar = makeVar(Boolean(localStorage.getItem(DARK_MODE)));

const httpLink = createUploadLink({
  // uri: "http://localhost:4000/graphql",
  uri: "https://api.tunachat.net/graphql",
});
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "admin-token": sessionStorage.getItem(TOKEN),
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
