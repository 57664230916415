import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import TypeDropDown from "../Dropdown/TypeDropDown";
import InputAreaItem from "../InputAreaItem";
import InputForm from "../InputForm";
import InputItem from "../InputItem";
import { CustomBtn } from "../Buttons";
import Modal from "../Modal";
// import { useHistory } from "react-router";

const ADMIN_UPDATE_PAGECONTENT = gql`
  mutation adminUpdatePageContent(
    $id: Int!
    $num: Int
    $contentType: ContentType
    $vimeoId: String
    $body: String
  ) {
    adminUpdatePageContent(
      id: $id
      num: $num
      contentType: $contentType
      vimeoId: $vimeoId
      body: $body
    ) {
      ok
      error
    }
  }
`;

const ADMIN_DELETE_PAGECONTENT = gql`
  mutation adminDeletePageContent($id: [Int]) {
    adminDeletePageContent(id: $id) {
      ok
      error
    }
  }
`;

const BottomSection = styled.div`
  width: 100%;
  display: flex;
`;
const BtnWrapper = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-left: 16px;
`;

const DetailActiveInfo = ({ marginBottom, data, refetch }) => {
  const { id, num, contentType, vimeoId, body } = data;
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    if (!data.num) {
      alert("세부 활동 번호를 선택해 주세요.");
      return;
    }
    adminUpdatePageContentMutation({
      variables: {
        id,
        contentType: data.contentType,
        num: parseInt(data.num),
        body: data.body,
        vimeoId: data.vimeoId,
      },
    });
  };
  // const history = useHistory();

  const [saveModal, setSaveModal] = useState(false);
  const onSaveModal = () => setSaveModal(true);
  const [deleteModal, setDeleteModel] = useState(false);
  const onDeleteModal = () => setDeleteModel(true);
  const close = () => {
    setSaveModal(false);
    setDeleteModel(false);
  };
  const [adminDeletePageContentMutation] = useMutation(
    ADMIN_DELETE_PAGECONTENT,
    {
      onCompleted: (d) => {
        if (d.adminDeletePageContent.ok) {
          close();
          refetch();
        }
      },
    }
  );
  const [adminUpdatePageContentMutation] = useMutation(
    ADMIN_UPDATE_PAGECONTENT,
    {
      onCompleted: (d) => {
        if (d.adminUpdatePageContent.ok) {
          onSaveModal();
        } else {
          alert(d.adminUpdatePageContent.error);
        }
      },
    }
  );
  return (
    <form
      onSubmit={handleSubmit(onValid)}
      style={{ marginBottom: marginBottom }}
    >
      <InputForm title="세부 활동 정보">
        <InputItem
          name="세부 활동 번호"
          value={num}
          register={register}
          formName="num"
          type="number"
        />
        <TypeDropDown
          name="세부 활동 유형"
          register={register}
          formName="contentType"
          value={contentType}
        />
        <InputItem
          name="세부 활동 링크 (*유형이 오디오인 경우 적용)  ex)https://vimeo.com/********"
          value={vimeoId}
          border
          register={register}
          formName="vimeoId"
        />
        <BottomSection>
          <div style={{ width: "80%" }}>
            <InputAreaItem
              name="세부 활동 설명"
              value={body}
              border
              width="100%"
              height="110px"
              register={register}
              formName="body"
            />
          </div>
          <BtnWrapper>
            <CustomBtn value="저장하기" width="100px" type="submit" />
            <CustomBtn
              value="삭제하기"
              red
              width="100px"
              onClick={onDeleteModal}
            />
          </BtnWrapper>
        </BottomSection>
        <Modal
          visible={saveModal}
          body="저장되었습니다."
          confirm={() => {
            close();
            refetch();
          }}
        />
        <Modal
          body="콘텐츠 삭제 시, 관련 정보와 하위 등록된 활동들도 모두 삭제되며 복구가 불가능합니다. 선택한 콘텐츠를 삭제하시겠습니까?"
          visible={deleteModal}
          close={close}
          confirm={() => {
            adminDeletePageContentMutation({
              variables: {
                id: [id],
              },
            });
          }}
        />
      </InputForm>
    </form>
  );
};

export default DetailActiveInfo;
