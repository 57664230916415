import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import Pagenation from "../../Components/Sheet/Pagenation";
import NoticeSheetHeader from "../../Components/Sheet/NoticeSheetHeader";
import { NoticeColumns } from "../../Data/site/Notice";
import NoticeSheetBody from "../../Components/Sheet/NoticeSheetBody";

const Container = styled.div`
  height: 615px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const NOTICES = gql`
  query notices($page: Int, $keyword: String) {
    notices(page: $page, keyword: $keyword) {
      ok
      error
      notices {
        id
        title
      }
      totalPages
    }
  }
`;

const ADMIN_DELETE_NOTICE = gql`
  mutation adminDeleteNotice($id: [Int]) {
    adminDeleteNotice(id: $id) {
      ok
      error
    }
  }
`;

const Notice = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(undefined);
  const history = useHistory();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const toggleSelectNotice = (id) => {
    if (selected.filter((notice) => notice === id).length !== 0) {
      setSelected(selected.filter((notice) => notice !== id));
    } else {
      setSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const selectAll = () => {
    if (selected.length < data?.notices?.notices?.length) {
      const prevArray = [];
      data?.notices?.notices?.map((notice) => {
        if (selected.filter((id) => notice === id).length === 0) {
          prevArray.push(notice.id);
        }
        return null;
      });
      setSelected(prevArray);
    } else {
      setSelected([]);
    }
  };
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading, refetch } = useQuery(NOTICES, {
    variables: { page, keyword },
    onCompleted: (d) => {
      if (d.notices.ok) {
        setTotalPages(d.notices.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setSelected([]);
    setPage(page);
  };
  const [adminDeleteNoticeMutation, { loading: mutationLoading }] = useMutation(
    ADMIN_DELETE_NOTICE,
    {
      onCompleted: (d) => {
        setPage(1);
        close();
        refetch();
      },
    }
  );
  const onDeleteAll = () => {
    if (!mutationLoading) {
      adminDeleteNoticeMutation({
        variables: { id: selected },
        onCompleted: (d) => {
          setPage(1);
          close();
          refetch();
        },
      });
    }
  };
  const goCreateNotice = () => {
    history.push("/createNotice", { beforePath: location.pathname });
  };
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <LayOut
      title="공지사항 관리"
      btn1Value="공지 생성"
      btn1Click={goCreateNotice}
      btn2Value="선택 삭제"
      red2
      btn2Click={onMoal}
    >
      <Search value={keyword} onChangeValue={onChangeKeyword} />
      <Container>
        {!loading && (
          <NoticeSheetHeader
            noticeColumns={NoticeColumns}
            checked={selected.length === data?.notices?.notices?.length}
            onClick={selectAll}
          />
        )}
        {!loading &&
          data?.notices?.notices?.map((item) => (
            <NoticeSheetBody
              {...item}
              key={item.id}
              selected={selected}
              toggleSelectNotice={toggleSelectNotice}
              onDelete={adminDeleteNoticeMutation}
            />
          ))}
      </Container>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      <Modal
        body="공지사항 삭제 시, 작성된 공지사항 내용이 삭제되며 복구가 불가능합니다. 선택한 공지사항을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={onDeleteAll}
      />
    </LayOut>
  );
};
export default Notice;
