import React from "react";
import styled from "styled-components";
import fonts from "../../Styles/fonts";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
`;

const Shell = styled.span`
  ${fonts.body1};
  color: ${(p) => p.theme.menuTitle};
  :first-child {
    width: 10%;
  }
`;

const CommentSheetHeader = ({ UserColumns }) => {
  const { first, second, third } = UserColumns;
  return (
    <Container>
      <Shell style={{ width: "40%" }}>{first}</Shell>
      <Shell style={{ width: "20%" }}>{second}</Shell>
      <Shell style={{ width: "30%" }}>{third}</Shell>
    </Container>
  );
};
export default CommentSheetHeader;
