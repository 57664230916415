import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import LayOut from "../../Components/LayOut";
import { useHistory, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import Modal from "../../Components/Modal";
import PartInfo from "../../Components/Site/PartInfo";

const ADMIN_GET_PART = gql`
  query adminGetPart($id: Int!) {
    adminGetPart(id: $id) {
      id
      type
      num
      thumbnail
    }
  }
`;
const ADMIN_UPDATE_PART = gql`
  mutation adminUpdatePart($id: Int!, $thumbnail: Upload) {
    adminUpdatePart(id: $id, thumbnail: $thumbnail) {
      ok
      error
    }
  }
`;

const PartDetail = () => {
  const location = useLocation();
  const {
    state: { id },
  } = location;
  const { data, loading } = useQuery(ADMIN_GET_PART, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    adminUpdatePartMutation({
      variables: {
        id,
        thumbnail: Boolean(data.thumbnail[0]) ? data.thumbnail[0] : undefined,
      },
    });
  };
  const [saveModal, setSaveModal] = useState(false);
  const onSaveModal = () => setSaveModal(true);
  const [adminUpdatePartMutation] = useMutation(ADMIN_UPDATE_PART, {
    onCompleted: (d) => {
      if (d.adminUpdatePart.ok) {
        onSaveModal();
      } else {
        alert(d.adminUpdatePart.error);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut title="파트 정보" btn1Value="저장하기" type1="submit">
        {!loading && (
          <PartInfo register={register} data={{ ...data?.adminGetPart }} />
        )}
        <Modal
          visible={saveModal}
          body="저장되었습니다."
          confirm={() => history.push("/part")}
        />
      </LayOut>
    </form>
  );
};

export default PartDetail;
