import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { SearchIcon } from "../../Icons/Icons";
import fonts from "../../Styles/fonts";
import { CustomBtn } from "../Buttons";
import CheckBox from "../CheckBox";
import Modal from "../Modal";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
  position: relative;
`;

const Shell = styled.span`
  ${fonts.body1};
  :first-child {
    width: 10%;
  }
`;

const Btn = styled.button`
  cursor: pointer;
`;

const CouponSheetBody = ({
  id,
  passType,
  code,
  creator,
  isUsed,
  selected,
  toggleSelectCoupon,
  onDelete,
}) => {
  const history = useHistory();
  const location = useLocation();
  const goCouponDetail = () => {
    history.push({
      pathname: "/couponDetail",
      state: {
        id: id,
        beforePath: location.pathname,
      },
    });
  };

  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <Container>
      <CheckBox
        marginRight="32px"
        checked={Boolean(
          selected.filter((coupon) => coupon === id).length !== 0
        )}
        onClick={() => toggleSelectCoupon(id)}
      />
      <Shell style={{ width: "10%" }}>
        <Btn
          type="button"
          onClick={() => {
            goCouponDetail();
          }}
        >
          <SearchIcon />
        </Btn>
      </Shell>
      <Shell style={{ width: "20%" }}>
        {(passType === "RECORDING" && "레코딩 이용권") ||
          (passType === "DRAWING" && "드로잉 이용권") ||
          (passType === "WRITING" && "라이팅 이용권")}
      </Shell>
      <Shell style={{ width: "25%" }}>{code}</Shell>
      <Shell style={{ width: "20%" }}>
        {creator?.username ? creator?.username : "관리자"}
      </Shell>
      <Shell style={{ width: "15%" }}>{isUsed ? "사용 완료" : "사용 전"}</Shell>
      <CustomBtn width="70px" value="삭제" red onClick={onMoal} />
      <Modal
        body="쿠폰 삭제 시, 삭제된 쿠폰번호는 다시 사용할 수 없으며 복구가 불가능합니다. 선택한 쿠폰을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={() => onDelete({ variables: { id: [id] } })}
      />
    </Container>
  );
};
export default CouponSheetBody;
