import React from "react";
import { getDate } from "../../utils";
import CouponDropdown from "../Dropdown/CouponDropdown";
import InputForm from "../InputForm";
import InputItem from "../InputItem";

const CouponInfo = ({ register, data }) => {
  const { passType, code, creator, isUsed, usedUser, usedDate } = data;
  return (
    <InputForm title="쿠폰번호 관리">
      <CouponDropdown
        name="쿠폰 종류"
        value={passType}
        register={register}
        formName="passType"
      />
      <InputItem
        name="쿠폰번호"
        register={register}
        value={code}
        formName="code"
        disable
      />
      <InputItem
        name="생성자"
        value={creator?.username ? creator?.username : "관리자"}
        register={register}
        formName="creator"
        disable
      />
      <InputItem
        name="사용여부"
        value={isUsed ? "사용완료" : "사용전"}
        register={register}
        formName="isUsed"
        disable
      />
      <InputItem
        name="사용자"
        value={isUsed ? usedUser?.username : "사용되지 않은 쿠폰입니다."}
        register={register}
        formName="usedUser"
        disable
      />
      <InputItem
        name="사용일"
        value={
          isUsed ? getDate(parseInt(usedDate)) : "사용되지 않은 쿠폰입니다."
        }
        register={register}
        formName="usedDate"
        disable
      />
    </InputForm>
  );
};

export default CouponInfo;
