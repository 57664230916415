import React from "react";
import { getDate } from "../../utils";
import VoucherDropdown from "../Dropdown/VoucherDropdown";
import InputForm from "../InputForm";
import InputItem from "../InputItem";

const UserInfo = ({ register, data }) => {
  const {
    username,
    email,
    phone,
    createdAt,
    recordingPass,
    drawingPass,
    writingPass,
  } = data;

  return (
    <>
      <InputForm title="회원 정보">
        <InputItem
          name="이메일 (아이디)"
          value={email}
          register={register}
          formName="email"
          disable
        />
        <InputItem
          name="가입일"
          value={getDate(parseInt(createdAt))}
          register={register}
          formName="createdAt"
          disable
        />
        <InputItem
          name="닉네임"
          value={username}
          border
          register={register}
          formName="username"
        />
        <InputItem
          name="휴대전화"
          value={phone}
          register={register}
          formName="phone"
          disable
        />
      </InputForm>
      <InputForm title="이용권 관리">
        <VoucherDropdown
          name="레코딩 이용권"
          register={register}
          value={recordingPass}
          formName="recordingPass"
        />
        <VoucherDropdown
          name="드로잉 이용권"
          register={register}
          value={drawingPass}
          formName="drawingPass"
        />
        <VoucherDropdown
          name="라이팅 이용권"
          register={register}
          value={writingPass}
          formName="writingPass"
        />
      </InputForm>
    </>
  );
};

export default UserInfo;
