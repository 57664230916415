export const lightTheme = {
  bgColor: "#F4F4F4",
  grey: "#9E9E9E",
  black: "#000",
  border: "#707070",
  menuBorder: "#DDDDDD",
  white: "#fff",
  blue: "#1C72E8",
  red: "#FF0000",
  menuTitle: "#5C5C5C",
  orange: "#EF5600",
  apricot: "#FCEFD0",
  darkBlue: "#232339",
  shadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
  dropdown: "#696969",
};

export const darkTheme = {
  bgColor: "#000",
  fontColor: "#fff",
};
