import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { getExactDate, reportConverter, typeConverter } from "../../utils";
import { CustomBtn } from "../Buttons";
import InputAreaItem from "../InputAreaItem";
import InputForm from "../InputForm";
import InputItem from "../InputItem";

const BottomSection = styled.div`
  width: 100%;
  display: flex;
`;
const BtnWrapper = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 24px;
  padding-left: 16px;
`;
// const NoBoardWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   padding-bottom: 24px;
// `;

const ReportInfo = ({ data }) => {
  const { type, createdAt, user, body, post, comment } = data;
  const history = useHistory();
  const goBoard = () => {
    history.push({
      pathname: "/boardDetail",
      state: {
        id: type === "POST" ? post?.id : comment?.post?.id,
      },
    });
  };
  return (
    <>
      <InputForm title="신고내역 정보">
        <InputItem name="신고 유형" value={reportConverter(type)} disable />
        <InputItem
          name="신고일자"
          value={getExactDate(parseInt(createdAt))}
          disable
        />
        <InputItem name="신고자" value={user?.username} disable />
        <InputItem
          name="피신고자"
          value={
            type === "POST" ? post?.user?.username : comment?.user?.username
          }
          disable
        />
        <InputAreaItem
          name="신고 내용"
          border
          value={body}
          disable
          width="100%"
          height="110px"
        />
      </InputForm>
      <InputForm title={`신고된 ${reportConverter(type)} 정보`}>
        <>
          {type === "POST" ? (
            <>
              <InputItem
                name="게시물 위치"
                value={typeConverter(post?.type)}
                disable
              />
              <InputItem
                name="작성일"
                value={getExactDate(parseInt(post?.createdAt))}
                disable
              />
              <InputItem name="제목" value={post?.page?.title} disable />
              <InputItem name="글쓴이" value={post?.user?.username} disable />
            </>
          ) : (
            <>
              <InputItem
                name="작성자"
                value={comment?.user?.username}
                disable
              />
              <InputItem
                name="작성일"
                value={getExactDate(parseInt(comment?.createdAt))}
                disable
              />
            </>
          )}

          <BottomSection>
            <InputAreaItem
              name={`${reportConverter(type)} 내용`}
              border
              value={type === "POST" ? post?.body : comment?.body}
              disable
              width="80%"
              height="110px"
            />
            <BtnWrapper>
              <CustomBtn value="게시물 이동" onClick={goBoard} />
            </BtnWrapper>
          </BottomSection>
        </>
      </InputForm>
    </>
  );
};

export default ReportInfo;
