import React, { useState } from "react";
import styled from "styled-components";
import fonts from "../../Styles/fonts";
import { getExactDate } from "../../utils";
import { CustomBtn } from "../Buttons";

import Modal from "../Modal";

const Container = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
  position: relative;
`;

const Shell = styled.span`
  ${fonts.body1};
  :first-child {
    width: 10%;
  }
`;

const CommentSheetBody = ({ id, body, user, createdAt, onDelete }) => {
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <Container>
      <Shell style={{ width: "40%" }}>{body}</Shell>
      <Shell style={{ width: "20%" }}>{user?.username}</Shell>
      <Shell style={{ width: "30%" }}>{getExactDate(createdAt)}</Shell>
      <CustomBtn width="70px" value="삭제" red onClick={onMoal} />
      <Modal
        body="댓글 삭제 시, 작성된 내용이 삭제되며 복구가 불가능합니다. 선택한 댓글을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={() => {
          onDelete({ variables: { id: [id] } });
        }}
      />
    </Container>
  );
};
export default CommentSheetBody;
