export const CouponColumns = {
  first: "상세보기",
  second: "쿠폰 종류",
  third: "쿠폰 번호",
  fourth: "생성자",
  fifth: "사용여부",
};

export const CouponData = [
  {
    id: 1,
    type: "RECORDING",
    num: "A44S56FG23WN",
    creator: "관리자1",
    use: true,
  },
  {
    id: 2,
    type: "DRAWING",
    num: "SD6587DW44GD",
    creator: "유저1",
    use: true,
  },
  {
    id: 3,
    type: "WRITING",
    num: "T7J8786HH34KL",
    creator: "관리자2",
    use: false,
  },
  {
    id: 4,
    type: "DRAWING",
    num: "N8J5GR67FD092",
    creator: "사용자7",
    use: false,
  },
  {
    id: 5,
    type: "DRAWING",
    num: "T6UN4AC9HA6T3",
    creator: "관리자",
    use: true,
  },
  {
    id: 6,
    type: "WRITING",
    num: "A44S56FG23WN",
    creator: "관리자",
    use: true,
  },
  {
    id: 7,
    type: "WRITING",
    num: "A44S56FG23WN",
    creator: "관리자",
    use: true,
  },
  {
    id: 8,
    type: "WRITING",
    num: "A44S56FG23WN",
    creator: "관리자",
    use: false,
  },
  {
    id: 9,
    type: "RECORDING",
    num: "A44S56FG23WN",
    creator: "관리자",
    use: false,
  },
  {
    id: 10,
    type: "RECORDING",
    num: "A44S56FG23WN",
    creator: "관리자",
    use: false,
  },
];
