import React from "react";
import PartDropdown from "../Dropdown/PartDropdown";
import SubPartDropdown from "../Dropdown/SubPartDropdown";
import InputAreaItem from "../InputAreaItem";
import InputForm from "../InputForm";
import InputItem from "../InputItem";
import InputItemPhoto from "../InputItemPhoto";

const ActiveInfo = ({ register, data, watch, setValue }) => {
  const { subPart, num, title, description, thumbnail } = data;
  return (
    <InputForm title="활동 정보">
      <PartDropdown
        name="파트 번호"
        register={register}
        formName="partId"
        type={subPart?.part?.type}
        value={subPart?.part?.id}
      />
      <SubPartDropdown
        name="콘텐츠 번호"
        register={register}
        formName="subPartId"
        partId={watch("partId") ?? subPart?.part?.id}
        type={subPart?.part?.type}
        value={subPart?.id}
        reset={() => setValue("subPartId", undefined)}
      />
      <InputItem
        name="활동 번호"
        value={num}
        border
        register={register}
        formName="num"
        type="number"
      />
      <InputItem
        name="활동 이름"
        value={title}
        register={register}
        formName="title"
      />
      <InputItemPhoto
        name="활동 이미지"
        register={register}
        formName="thumbnail"
        value={thumbnail}
      />
      <InputAreaItem
        name="활동 설명"
        border
        width="100%"
        height="110px"
        register={register}
        value={description}
        formName="description"
      />
    </InputForm>
  );
};

export default ActiveInfo;
