const fonts = {
  headline1: `
    font-weight: 300;
    font-size: 96px;
    letter-spacing: -0.01em;
    line-height: 112px;
    text-align: left;
    `,
  headline2: `
    font-weight: 300;
    font-size: 60px;
    letter-spacing: -0.01em;
    line-height: 72px;
    text-align: left;
    `,
  headline3: ` 
    font-weight: normal;
    font-size: 48px;
    line-height: 56px;
    text-align: left;
    `,
  headline4: `    
    font-weight: normal;
    font-size: 34px;
    line-height: 36px;
    text-align: left;
    `,
  headline5: `
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0.01em;
    line-height: 24px;
    text-align: left;
    `,
  headline6: `
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 30px;
    text-align: left;
    `,
  subtitle1: `
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 20px;
    text-align: left;
    `,
  subtitle2: `
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 18px;
    text-align: left;
  `,
  subtitle3: `
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.01em;
    line-height: 16px;
    text-align: left;
`,
  body1: `
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.03em;
    line-height: 24px;
    text-align: left;
`,
  body2: `
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 20px;
    text-align: left;
`,
  body3: `
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.02em;
    line-height: 20px;
    text-align: left;
`,
  button: `
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.09em;
    line-height: 16px;
    text-align: left;
`,
  caption: `
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.03em;
    line-height: 16px;
    text-align: left;
`,
  overline: `
    font-weight: normal;
    font-size: 10px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-align: left;
`,
};
export default fonts;
