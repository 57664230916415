import React from "react";
import styled from "styled-components";
import { CheckedIcon, CheckOutLineIcon } from "../Icons/Icons";

const Btn = styled.button`
  margin-right: ${(p) => (p.marginRight ? p.marginRight : 0)};
`;

const CheckBox = ({ marginRight, checked, onClick }) => {
  return checked ? (
    <Btn onClick={onClick} marginRight={marginRight}>
      <CheckedIcon />
    </Btn>
  ) : (
    <Btn onClick={onClick} marginRight={marginRight}>
      <CheckOutLineIcon />
    </Btn>
  );
};

export default CheckBox;
