import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
    ${reset};
    * {
        box-sizing: border-box;
    }
    body{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    a {
        text-decoration: none;   
        color: inherit;
    }
    input{
        all:unset;
        border:none;
        :focus{
        outline: none
    }
    }
    button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    #root {
        display: flex;   
    }
`;

export default GlobalStyles;
