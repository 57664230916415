import * as React from "react";

export const LockIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45px"
      height="45px"
      viewBox="0 0 45 45"
      {...props}
    >
      <g data-name="\uADF8\uB8F9 278" transform="translate(-5333 1505)">
        <circle
          data-name="\uD0C0\uC6D0 341"
          cx={22.5}
          cy={22.5}
          r={22.5}
          transform="translate(5333 -1505)"
          fill="#1c72e8"
        />
        <path
          data-name="Icon ionic-md-lock"
          d="M5362.14-1486.934h-1.153v-2.3a5.764 5.764 0 10-11.528 0v2.3h-1.153a2.312 2.312 0 00-2.306 2.31v11.529a2.312 2.312 0 002.306 2.306h13.834a2.312 2.312 0 002.31-2.306v-11.529a2.312 2.312 0 00-2.31-2.31zm-6.917 10.5a2.306 2.306 0 112.306-2.306 2.312 2.312 0 01-2.306 2.306zm3.574-10.5h-7.147v-2.3a3.574 3.574 0 117.148 0z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export const MinusIcon = (props) => {
  return (
    <svg
      data-name="Icon feather-minus"
      xmlns="http://www.w3.org/2000/svg"
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M0 0h32v32H0z" fill="none" opacity={0.6} />
      <path
        data-name="Icon feather-minus"
        d="M6.5 16.5h18.666"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.667}
      />
    </svg>
  );
};

export const PlusIcon = (props) => {
  return (
    <svg
      data-name="Icon feather-plus"
      xmlns="http://www.w3.org/2000/svg"
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M0 0h32v32H0z" fill="none" opacity={0.6} />
      <g
        data-name="Icon feather-plus"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.667}
      >
        <path data-name="\uD328\uC2A4 94" d="M16.333 6.667v18.666" />
        <path data-name="\uD328\uC2A4 95" d="M7 16h18.666" />
      </g>
    </svg>
  );
};

export const ReloadIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32px"
      height="32px"
      viewBox="0 0 32.867 32"
      {...props}
    >
      <g data-name="Icon feather-refresh-ccw" fill="none">
        <path d="M.434 0h32v32h-32z" opacity={0.6} />
        <g
          data-name="Icon feather-refresh-ccw"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.5}
        >
          <path data-name="\uD328\uC2A4 91" d="M1.767 5.333v8h8" />
          <path data-name="\uD328\uC2A4 92" d="M31.101 26.667v-8h-8" />
          <path
            data-name="\uD328\uC2A4 93"
            d="M27.754 12a12 12 0 00-19.8-4.48l-6.187 5.813M31.1 18.666l-6.186 5.814A12 12 0 015.114 20"
          />
        </g>
      </g>
    </svg>
  );
};

export const SearchIcon = (props) => {
  return (
    <svg
      data-name="Icon feather-search"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" opacity={0.6} />
      <g
        data-name="Icon feather-search"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="\uD328\uC2A4 155"
          d="M19 11a8 8 0 11-8-8 8 8 0 018 8z"
        />
        <path data-name="\uD328\uC2A4 156" d="M21 21l-4.35-4.35" />
      </g>
    </svg>
  );
};

export const CheckedIcon = (props) => {
  return (
    <svg
      data-name="Icon feather-check-square"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" opacity={0.6} />
      <g
        data-name="Icon feather-check-square"
        fill="none"
        stroke="#232339"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path data-name="\uD328\uC2A4 206" d="M9 11l3 3L22 4" />
        <path
          data-name="\uD328\uC2A4 207"
          d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
        />
      </g>
    </svg>
  );
};

export const CheckOutLineIcon = (props) => {
  return (
    <svg
      data-name="Icon feather-square"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" opacity={0.6} />
      <path
        data-name="Icon feather-square"
        d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2z"
        fill="none"
        stroke="#748a9d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export const ChevronDownIcon = (props) => {
  return (
    <svg
      data-name="Icon feather-chevron-down"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" opacity={0.6} />
      <path
        data-name="Icon feather-chevron-down"
        d="M6 9l6 6 6-6"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export const ChevronUpIcon = (props) => {
  return (
    <svg
      data-name="Icon feather-chevron-up"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" opacity={0.6} />
      <path
        data-name="Icon feather-chevron-up"
        d="M18 15l-6-6-6 6"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export const UploadIcon = (props) => {
  return (
    <svg
      data-name="Icon feather-upload"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" opacity={0.6} />
      <g
        data-name="Icon feather-upload"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="\uD328\uC2A4 157"
          d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4"
        />
        <path data-name="\uD328\uC2A4 158" d="M17 8l-5-5-5 5" />
        <path data-name="\uD328\uC2A4 159" d="M12 3v12" />
      </g>
    </svg>
  );
};
