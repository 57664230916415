export const getDate = (startedDate) => {
  const newDate = new Date(parseInt(startedDate));
  return `${newDate.getFullYear()}년 ${
    newDate.getMonth() + 1
  }월 ${newDate.getDate()}일`;
};
export const getExactDate = (startedDate) => {
  const newDate = new Date(parseInt(startedDate));
  return `${newDate.getFullYear()}년 ${
    newDate.getMonth() + 1
  }월 ${newDate.getDate()}일 ${
    newDate.getHours() > 9 ? "" : "0"
  }${newDate.getHours()}:${
    newDate.getMinutes() > 9 ? "" : "0"
  }${newDate.getMinutes()}:${
    newDate.getSeconds() > 9 ? "" : "0"
  }${newDate.getSeconds()}`;
};

export const randomCode = () => {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const code_len = 12;
  let randomCode = "";
  for (let i = 0; i < code_len; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    randomCode += chars.charAt(randomIndex, randomIndex + 1);
  }
  return randomCode;
};

export const pathConverter = (type) => {
  switch (type) {
    case "RECORDING":
      return "/recordingC";
    case "DRAWING":
      return "/drawingC";
    case "WRITING":
      return "/writingC";
    default:
      return;
  }
};
export const partConverter = (type) => {
  switch (type) {
    case "RECORDING":
      return "레코딩";
    case "DRAWING":
      return "드로잉";
    case "WRITING":
      return "라이팅";
    default:
      return;
  }
};
export const typeConverter = (type) => {
  switch (type) {
    case "RECORDING":
      return "레코딩 게시판";
    case "DRAWING":
      return "드로잉 게시판";
    case "WRITING":
      return "라이팅 게시판";
    default:
      return;
  }
};
export const paymentConverter = (type) => {
  switch (type) {
    case "RECORDING":
      return "레코딩 이용권";
    case "DRAWING":
      return "드로잉 이용권";
    case "WRITING":
      return "라이팅 이용권";
    default:
      return;
  }
};
export const reportConverter = (type) => {
  switch (type) {
    case "POST":
      return "게시글";
    case "COMMENT":
      return "댓글";
    default:
      return;
  }
};
