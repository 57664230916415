import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import PartDropdown from "../../Components/Dropdown/PartDropdown";
import InputAreaItem from "../../Components/InputAreaItem";
import InputForm from "../../Components/InputForm";
import InputItem from "../../Components/InputItem";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";

const ADMIN_CREATE_SUBPART = gql`
  mutation adminCreateSubPart(
    $title: String
    $description: String
    $partId: Int!
    $type: MainType!
    $num: Int
  ) {
    adminCreateSubPart(
      title: $title
      description: $description
      partId: $partId
      type: $type
      num: $num
    ) {
      ok
      error
    }
  }
`;

const CreateContents = () => {
  const [adminCreateSubPartMutation] = useMutation(ADMIN_CREATE_SUBPART, {
    onCompleted: (d) => {
      if (d.adminCreateSubPart.ok) {
        onMoal();
      } else {
        alert(d.adminCreateSubPart.error);
      }
    },
  });
  const location = useLocation();
  const {
    state: { type, beforePath },
  } = location;
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const onValid = (data) => {
    if (data.title && data.num && data.partId && data.description) {
      adminCreateSubPartMutation({
        variables: {
          title: data.title,
          description: data.description,
          num: parseInt(data.num),
          partId: parseInt(data.partId),
          type,
        },
      });
    } else {
      alert("모든 항목을 입력해 주세요");
    }
  };
  const [isModal, setIsModal] = useState(false);
  const onMoal = () => setIsModal(true);
  const close = () => {
    setIsModal(false);
    history.push(beforePath);
  };

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <LayOut title="콘텐츠 생성" btn1Value="저장하기" type1="submit">
        <InputForm title="콘텐츠 정보">
          <PartDropdown
            name="파트 번호"
            register={register}
            formName="partId"
            type={type}
          />
          <InputItem
            name="콘텐츠 번호"
            width="320px"
            placeholder="콘텐츠 번호를 입력해주세요. (숫자만 가능)"
            border
            register={register}
            formName="num"
            type="number"
          />
          <InputItem
            name="콘텐츠 제목"
            placeholder="콘텐츠 제목을 입력해주세요."
            register={register}
            formName="title"
          />
          <InputAreaItem
            name="콘텐츠 설명"
            placeholder="콘텐츠 설명을 입력해주세요."
            border
            width="100%"
            height="110px"
            register={register}
            formName="description"
          />
        </InputForm>
        <Modal visible={isModal} body="저장되었습니다." confirm={close} />
      </LayOut>
    </form>
  );
};

export default CreateContents;
