import React, { useState } from "react";
import styled from "styled-components";
import { UploadIcon } from "../Icons/Icons";
import fonts from "../Styles/fonts";

const Contianer = styled.div`
  width: ${(p) => (p.width ? p.width : "310px")};
  position: relative;
  border-bottom: ${(p) => (p.border ? "none" : "1px solid black")};
  margin-right: 36px;
  margin-bottom: 24px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const Name = styled.span`
  ${fonts.body2}
  display: block;
  margin-bottom: 16px;
`;
const Input = styled.input`
  display: none;
`;
const Label = styled.label`
  cursor: pointer;
`;

const InputItemPhoto = ({ width, name, register, formName, value }) => {
  const [fileName, setFileName] = useState(value ?? "");
  const onChange = (e) => {
    const {
      target: { files },
    } = e;
    if (files) {
      setFileName(files[0]?.name);
    } else {
      setFileName("이미지를 다시 업로드 해주세요.");
    }
  };
  return (
    <Contianer width={width}>
      <Name>{name}</Name>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Input
          type="file"
          id="photo"
          accept="image/jpg, image/png, image/jpeg, image/gif"
          {...register(formName)}
          onChange={(e) => {
            onChange(e);
            register(formName).onChange(e);
          }}
        />
        <div
          style={{ width: "100%", wordBreak: "break-all" }}
        >{`파일 이름: ${fileName}`}</div>
        <Label htmlFor="photo">
          <UploadIcon />
        </Label>
      </div>
    </Contianer>
  );
};

export default InputItemPhoto;
