import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 13px 16px 13px 32px;
  background-color: ${(p) => (p.isHere ? p.theme.apricot : p.theme.white)};
  cursor: pointer;
`;
const MenuListText = styled.span`
  font-size: 16px;
  color: ${(p) => (p.isHere ? p.theme.orange : p.theme.menuTitle)};
  font-weight: ${(p) => (p.isHere ? "800" : "300")};
`;

const MenuList = ({ id, title, path, current }) => {
  const isHere = current === path;

  return (
    <Link to={path}>
      <Container key={id} isHere={isHere}>
        <MenuListText isHere={isHere}>{title}</MenuListText>
      </Container>
    </Link>
  );
};

export default MenuList;
