import React from "react";
import styled from "styled-components";
import PageHeader from "./Header/PageHeader";

const Container = styled.div`
  width: 100%;
  height: ${(p) => (p.half ? "50%" : "100%")};
  background-color: ${(p) => p.theme.white};
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : 0)};
`;

const LayOut = ({
  children,
  title,
  btn1Value,
  red1,
  btn2Value,
  btn1Click,
  btn2Click,
  red2,
  half,
  marginBottom,
  type1,
  type2,
}) => {
  return (
    <Container half={half} marginBottom={marginBottom}>
      <PageHeader
        title={title}
        btnValue={btn1Value}
        red={red1}
        btn2Value={btn2Value}
        red2={red2}
        btn1Click={btn1Click}
        btn2Click={btn2Click}
        type1={type1}
        type2={type2}
      />
      {children}
    </Container>
  );
};

export default LayOut;
