import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import Pagenation from "../../Components/Sheet/Pagenation";
import CouponSheetHeader from "../../Components/Sheet/CouponSheetHeader";
import { CouponColumns } from "../../Data/site/Coupon";
import CouponSheetBody from "../../Components/Sheet/CouponSheetBody";
import {
  SearchDropdownList1,
  SearchDropdownList2,
} from "../../Data/DropdownData";
import SearchDropdown from "../../Components/Dropdown/SearchDropdown";

const Container = styled.div`
  height: 615px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const COUPONS = gql`
  query coupons(
    $page: Int
    $keyword: String
    $passType: MainType
    $isUsed: String
  ) {
    coupons(
      page: $page
      keyword: $keyword
      passType: $passType
      isUsed: $isUsed
    ) {
      ok
      error
      coupons {
        id
        passType
        code
        creator {
          id
          username
        }
        isUsed
      }
      totalPages
    }
  }
`;

const ADMIN_DELETE_COUPON = gql`
  mutation adminDeleteCoupon($id: [Int]) {
    adminDeleteCoupon(id: $id) {
      ok
      error
    }
  }
`;

const Coupon = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(undefined);
  const history = useHistory();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [passType, setPassType] = useState(undefined);
  const [isUsed, setIsUsed] = useState(undefined);
  const toggleSelectCoupon = (id) => {
    if (selected.filter((coupon) => coupon === id).length !== 0) {
      setSelected(selected.filter((coupon) => coupon !== id));
    } else {
      setSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const selectAll = () => {
    if (selected.length < data?.coupons?.coupons?.length) {
      const prevArray = [];
      data?.coupons?.coupons?.map((coupon) => {
        if (selected.filter((id) => coupon === id).length === 0) {
          prevArray.push(coupon.id);
        }
        return null;
      });
      setSelected(prevArray);
    } else {
      setSelected([]);
    }
  };
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading, refetch } = useQuery(COUPONS, {
    variables: {
      page,
      keyword,
      isUsed: isUsed === "" ? undefined : isUsed,
      passType: passType === "" ? undefined : passType,
    },
    onCompleted: (d) => {
      if (d.coupons.ok) {
        setTotalPages(d.coupons.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setSelected([]);
    setPage(page);
  };
  const [adminDeleteCouponMutation, { loading: mutationLoading }] = useMutation(
    ADMIN_DELETE_COUPON,
    {
      onCompleted: (d) => {
        setPage(1);
        close();
        refetch();
      },
    }
  );
  const onDeleteAll = () => {
    if (!mutationLoading) {
      adminDeleteCouponMutation({
        variables: { id: selected },
        onCompleted: (d) => {
          setPage(1);
          close();
          refetch();
        },
      });
    }
  };
  const goCreateCoupon = () => {
    history.push({
      pathname: "/createCoupon",
      state: { id: 1, beforePath: location.pathname },
    });
  };
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);

  return (
    <LayOut
      title="쿠폰번호 관리"
      btn1Value="쿠폰 생성"
      btn1Click={goCreateCoupon}
      btn2Value="선택 삭제"
      red2
      btn2Click={onMoal}
    >
      <Search value={keyword} onChangeValue={onChangeKeyword}>
        <SearchDropdown
          data={SearchDropdownList1}
          defaultName="전체 쿠폰 종류"
          value={passType}
          setValue={setPassType}
          reset={() => null}
        />
        <SearchDropdown
          data={SearchDropdownList2}
          defaultName="전체 사용여부"
          value={isUsed}
          setValue={setIsUsed}
          reset={() => null}
        />
      </Search>
      <Container>
        {!loading && (
          <CouponSheetHeader
            CouponColumns={CouponColumns}
            checked={selected.length === data?.coupons?.coupons?.length}
            onClick={selectAll}
          />
        )}
        {!loading &&
          data?.coupons?.coupons?.map((item) => (
            <CouponSheetBody
              {...item}
              key={item.id}
              selected={selected}
              toggleSelectCoupon={toggleSelectCoupon}
              onDelete={adminDeleteCouponMutation}
            />
          ))}
      </Container>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      <Modal
        body="쿠폰 삭제 시, 삭제된 쿠폰번호는 다시 사용할 수 없으며 복구가 불가능합니다. 선택한 쿠폰을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={onDeleteAll}
      />
    </LayOut>
  );
};
export default Coupon;
