import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import TypeDropDown from "../Dropdown/TypeDropDown";
import InputAreaItem from "../InputAreaItem";
import InputItem from "../InputItem";
import { CustomBtn } from "../Buttons";
import Modal from "../Modal";

const Container = styled.div`
  width: 100%;
  box-shadow: ${(p) => p.theme.shadow};
  border-radius: 8px;
  padding: 24px 24px 0 24px;
  display: flex;
  flex-wrap: wrap;
`;

const BottomSection = styled.div`
  width: 100%;
  display: flex;
`;
const BtnWrapper = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-left: 16px;
`;

const ADMIN_CREATE_PAGECONTENT = gql`
  mutation adminCreatePageContent(
    $pageId: Int!
    $num: Int!
    $contentType: ContentType!
    $vimeoId: String
    $body: String
  ) {
    adminCreatePageContent(
      pageId: $pageId
      num: $num
      contentType: $contentType
      vimeoId: $vimeoId
      body: $body
    ) {
      ok
      error
    }
  }
`;

const CreateDetailActive = ({ pageId, refetch }) => {
  const { register, handleSubmit, setValue } = useForm();
  const onValid = (data) => {
    if (!data.num) {
      alert("세부 활동 번호를 선택해 주세요.");
      return;
    }
    if (!data.contentType) {
      alert("세부 활동 유형을 선택해 주세요.");
      return;
    }
    adminCreatePageContentMutation({
      variables: {
        pageId,
        num: parseInt(data.num),
        contentType: data.contentType,
        body: data.body,
        vimeoId: data.vimeoId,
      },
    });
  };
  const [saveModal, setSaveModal] = useState(false);
  const onSaveModal = () => setSaveModal(true);
  const close = () => {
    setSaveModal(false);
  };
  const [adminCreatePageContentMutation] = useMutation(
    ADMIN_CREATE_PAGECONTENT,
    {
      onCompleted: (d) => {
        if (d.adminCreatePageContent.ok) {
          onSaveModal();
        } else {
          alert(d.adminCreatePageContent.error);
        }
      },
    }
  );
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Container>
        <InputItem
          name="세부 활동 번호"
          placeholder="세부 활동 번호를 입력해주세요."
          register={register}
          formName="num"
          type="number"
        />
        <TypeDropDown
          name="세부 활동 유형"
          register={register}
          formName="contentType"
          value="TEXT"
        />
        <InputItem
          name="세부 활동 링크 (*유형이 오디오인 경우 적용) ex)https://vimeo.com/********"
          placeholder="링크를 입력해주세요."
          border
          register={register}
          formName="vimeoId"
        />
        <BottomSection>
          <div style={{ width: "80%" }}>
            <InputAreaItem
              name="세부 활동 설명"
              placeholder="세부 활동에 대한 설명을 입력해주세요."
              border
              width="100%"
              height="110px"
              register={register}
              formName="body"
            />
          </div>
          <BtnWrapper>
            <CustomBtn value="저장하기" width="100px" type="submit" />
            <CustomBtn value="삭제하기" red width="100px" />
          </BtnWrapper>
        </BottomSection>
        <Modal
          visible={saveModal}
          body="저장되었습니다."
          confirm={() => {
            close();
            setValue("num", "");
            setValue("contentType", "TEXT");
            setValue("vimeoId", "");
            setValue("body", "");
            refetch();
          }}
        />
      </Container>
    </form>
  );
};

export default CreateDetailActive;
