import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import LayOut from "../../Components/LayOut";
import { useLocation, useHistory } from "react-router";
import Modal from "../../Components/Modal";
import ReportInfo from "../../Components/Board/ReportInfo";
// import ReportInfoMore from "../../Components/Board/ReportInfoMore";

const REPORT = gql`
  query report($id: Int!) {
    report(id: $id) {
      id
      type
      body
      createdAt
      user {
        username
      }
      post {
        id
        type
        body
        user {
          username
        }
        page {
          title
        }
        createdAt
      }
      comment {
        id
        body
        user {
          username
        }
        post {
          id
        }
        createdAt
      }
    }
  }
`;
const ADMIN_DELETE_REPORT = gql`
  mutation adminDeleteReport($id: [Int]) {
    adminDeleteReport(id: $id) {
      ok
      error
    }
  }
`;

const ReportDetail = () => {
  const location = useLocation();
  const {
    state: { id },
  } = location;
  const { data, loading } = useQuery(REPORT, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();
  const [deleteModal, setDeleteModel] = useState(false);
  const onDeleteModal = () => setDeleteModel(true);
  const close = () => {
    setDeleteModel(false);
  };
  const [adminDeleteReportMutation] = useMutation(ADMIN_DELETE_REPORT, {
    onCompleted: (d) => {
      if (d.adminDeleteReport.ok) {
        close();
        history.push("/report");
      }
    },
  });
  return (
    <LayOut
      title="신고내역 정보"
      btn1Value="신고 삭제"
      red1
      btn1Click={onDeleteModal}
    >
      {!loading && <ReportInfo data={data?.report} />}
      <Modal
        body="게시물 삭제 시, 작성된 내용과 하위 등록된 댓글도 모두 삭제되며 복구가 불가능합니다. 선택한 게시물을 삭제하시겠습니까?"
        visible={deleteModal}
        close={close}
        confirm={() => {
          adminDeleteReportMutation({
            variables: {
              id: [id],
            },
          });
        }}
      />
    </LayOut>
  );
};

export default ReportDetail;
