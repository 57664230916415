import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import LayOut from "../../Components/LayOut";
import Modal from "../../Components/Modal";
import Search from "../../Components/Search";
import Pagenation from "../../Components/Sheet/Pagenation";

import RandomAlarmSheetHeader from "../../Components/Sheet/RandomAlarmSheetHeader";
import { RandomAlarmColumns } from "../../Data/site/RamdomAlarm";
import RandomAlarmSheetBody from "../../Components/Sheet/RandomAlarmSheetBody";

const Container = styled.div`
  height: 615px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadow};
  margin-bottom: 32px;
`;

const HEALINGS = gql`
  query healings($page: Int, $keyword: String) {
    healings(page: $page, keyword: $keyword) {
      ok
      error
      healings {
        id
        title
      }
      totalPages
    }
  }
`;

const ADMIN_DELETE_HEALING = gql`
  mutation adminDeleteHealing($id: [Int]) {
    adminDeleteHealing(id: $id) {
      ok
      error
    }
  }
`;

const RandomAlarm = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(undefined);
  const history = useHistory();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const toggleSelectHealing = (id) => {
    if (selected.filter((healing) => healing === id).length !== 0) {
      setSelected(selected.filter((healing) => healing !== id));
    } else {
      setSelected((prev) => {
        return [...prev, id];
      });
    }
  };
  const selectAll = () => {
    if (selected.length < data?.healings?.healings?.length) {
      const prevArray = [];
      data?.healings?.healings?.map((healing) => {
        if (selected.filter((id) => healing === id).length === 0) {
          prevArray.push(healing.id);
        }
        return null;
      });
      setSelected(prevArray);
    } else {
      setSelected([]);
    }
  };
  const onChangeKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };
  const { data, loading, refetch } = useQuery(HEALINGS, {
    variables: { page, keyword },
    onCompleted: (d) => {
      if (d.healings.ok) {
        setTotalPages(d.healings.totalPages);
      }
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const onClickPage = (page) => {
    setSelected([]);
    setPage(page);
  };
  const [adminDeleteHealingMutation, { loading: mutationLoading }] =
    useMutation(ADMIN_DELETE_HEALING, {
      onCompleted: (d) => {
        setPage(1);
        close();
        refetch();
      },
    });
  const onDeleteAll = () => {
    if (!mutationLoading) {
      adminDeleteHealingMutation({
        variables: { id: selected },
        onCompleted: (d) => {
          setPage(1);
          close();
          refetch();
        },
      });
    }
  };
  const goCreateRandomAlarm = () => {
    history.push({
      pathname: "/createRandomAlarm",
      state: { id: 1, beforePath: location.pathname },
    });
  };
  const onMoal = () => setIsModal(true);
  const close = () => setIsModal(false);
  return (
    <LayOut
      title="랜덤 힐링문구 알림 관리"
      btn1Value="알림 생성"
      btn1Click={goCreateRandomAlarm}
      btn2Value="선택 삭제"
      red2
      btn2Click={onMoal}
    >
      <Search value={keyword} onChangeValue={onChangeKeyword} />
      <Container>
        {!loading && (
          <RandomAlarmSheetHeader
            HealingColumns={RandomAlarmColumns}
            checked={selected.length === data?.healings?.healings?.length}
            onClick={selectAll}
          />
        )}
        {!loading &&
          data?.healings?.healings?.map((item) => (
            <RandomAlarmSheetBody
              {...item}
              key={item.id}
              selected={selected}
              toggleSelectHealing={toggleSelectHealing}
              onDelete={adminDeleteHealingMutation}
            />
          ))}
      </Container>
      <Pagenation total={totalPages} page={page} onClickPage={onClickPage} />
      <Modal
        body="알림 삭제 시, 작성된 알림 내용이 삭제되며 복구가 불가능합니다. 선택한 알림을 삭제하시겠습니까?"
        visible={isModal}
        close={close}
        confirm={onDeleteAll}
      />
    </LayOut>
  );
};
export default RandomAlarm;
