import React from "react";
import styled from "styled-components";
import fonts from "../../Styles/fonts";
// import CheckBox from "../CheckBox";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.menuBorder};
  padding: 16px;
`;

const Shell = styled.span`
  ${fonts.body1};
  color: ${(p) => p.theme.menuTitle};
  :first-child {
    width: 10%;
  }
`;

const PaymentSheetHeader = ({ UserColumns }) => {
  const { first, second, third } = UserColumns;
  return (
    <Container>
      {/* <CheckBox marginRight="32px" /> */}
      <Shell style={{ width: "30%", marginLeft: "48px" }}>{first}</Shell>
      <Shell style={{ width: "30%" }}>{second}</Shell>
      <Shell style={{ width: "30%" }}>{third}</Shell>
    </Container>
  );
};
export default PaymentSheetHeader;
