import React, { useState } from "react";
import styled from "styled-components";
import { logUserOut } from "../../client";
import { ReloadIcon } from "../../Icons/Icons";
import { CustomBtn } from "../Buttons";
import Modal from "../Modal";

const Container = styled.div`
  width: 100%;
  position: fixed;
  padding: 21px 24px 21px 40px;
  background-color: ${(p) => p.theme.darkBlue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 888;
`;

const AppName = styled.span`
  font-size: 20px;
  color: ${(p) => p.theme.white};
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const Btn = styled.button`
  cursor: pointer;
  margin-right: 32px;
`;

const AppHeader = () => {
  const [isModal, setIsModal] = useState(false);
  const onClickLogout = () => setIsModal(true);
  const closeModal = () => setIsModal(false);
  return (
    <Container>
      <AppName>TUNACHAT ADMIN</AppName>
      <RightSection>
        <Btn onClick={() => window.location.reload()}>
          <ReloadIcon />
        </Btn>
        <CustomBtn
          width="110px"
          value="LOGOUT"
          type="submit"
          onClick={() => onClickLogout()}
        />
        <Modal
          visible={isModal}
          body="로그아웃 하시겠습니까?"
          close={closeModal}
          confirm={logUserOut}
        />
      </RightSection>
    </Container>
  );
};

export default AppHeader;
