import React from "react";
import styled from "styled-components";
import fonts from "../../Styles/fonts";

const Contianer = styled.div`
  width: 140px;
  height: 37px;
  border-bottom: ${(p) => (p.border ? "none" : "1px solid black")};
  margin-right: 24px;
`;

const Select = styled.select`
  ${fonts.body2}
  width: 100%;
  height: 36px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: ${fonts.body1};
  color: ${(p) => p.theme.dropdown};
`;
const Option = styled.option`
  width: 100%;
  font-size: 16px;
  font-family: ${fonts.body1};
  color: ${(p) => p.theme.black};
`;

const SearchDropdown = ({
  defaultName,
  data,
  queryData,
  value,
  setValue,
  reset,
}) => {
  const onChange = (e) => {
    if (data) {
      reset();
    }
    setValue(e.target.value);
  };
  return (
    <>
      <Contianer>
        <Select value={value} onChange={onChange}>
          <Option value={""}>{defaultName}</Option>
          {data &&
            data.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.name}
              </Option>
            ))}
          {queryData &&
            queryData.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.num}
              </Option>
            ))}
        </Select>
      </Contianer>
    </>
  );
};

export default SearchDropdown;
