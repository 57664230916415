import React from "react";
import styled, { css } from "styled-components";
import fonts from "../Styles/fonts";

const Contianer = styled.div`
  width: ${(p) => (p.width ? p.width : "310px")};

  border-bottom: ${(p) => (p.border ? "none" : "1px solid black")};
  margin-right: 36px;
  margin-bottom: 24px;
`;
const Name = styled.span`
  ${fonts.body2}
  display: block;
  margin-bottom: 16px;
`;

const Input = styled.textarea`
  width: 100%;
  height: ${(p) => (p.height ? p.height : "fit-content")};
  white-space: pre-line;
  border: none;
  outline: none;
  resize: none;
  ${(p) =>
    p.border &&
    css`
      border: 1px solid ${(p) => p.theme.menuBorder};
      padding: 4px 0 4px 8px;
      border-radius: 4px;
      text-align: initial;
      ::placeholder {
        color: black;
      }
    `}
`;
const InputAreaItem = ({
  width,
  placeholder,
  name,
  type,
  border,
  height,
  register,
  formName,
  value,
  disable,
}) => {
  return (
    <Contianer width={width} border={border}>
      <Name>{name}</Name>
      <Input
        type={type ? type : "text"}
        placeholder={placeholder}
        defaultValue={value ? value : undefined}
        disabled={disable ? true : false}
        border={border}
        height={height}
        {...(register && { ...register(formName, { require: true }) })}
      />
    </Contianer>
  );
};

export default InputAreaItem;
