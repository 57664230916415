export const PaymentColumns = {
  first: "결제일",
  second: "구매 항목",
  third: "구매자",
};

export const PaymentData = [
  {
    id: 1,
    createdAt: "2021.08.23 12:32:23",
    goods: "레코딩 이용권",
    user: "사용자1",
  },
  {
    id: 2,
    createdAt: "2021.08.23 12:32:23",
    goods: "라이팅 이용권",
    user: "사용자2",
  },
  {
    id: 3,
    createdAt: "2021.08.23 12:32:23",
    goods: "드로잉 이용권",
    user: "사용자3",
  },
  {
    id: 4,
    createdAt: "2021.08.23 12:32:23",
    goods: "레코딩 이용권",
    user: "사용자4",
  },
  {
    id: 5,
    createdAt: "2021.08.23 12:32:23",
    goods: "레코딩 이용권",
    user: "사용자4",
  },
  {
    id: 6,
    createdAt: "2021.08.23 12:32:23",
    goods: "레코딩 이용권",
    user: "사용자3",
  },
  {
    id: 7,
    createdAt: "2021.08.23 12:32:23",
    goods: "레코딩 이용권",
    user: "사용자3",
  },
  {
    id: 8,
    createdAt: "2021.08.23 12:32:23",
    goods: "레코딩 이용권",
    user: "사용자2",
  },
  {
    id: 9,
    createdAt: "2021.08.23 12:32:23",
    goods: "레코딩 이용권",
    user: "사용자1",
  },
  {
    id: 10,
    createdAt: "2021.08.23 12:32:23",
    goods: "레코딩 이용권",
    user: "사용자1",
  },
];
