export const SearchPartList = [
  {
    id: 1,
    value: 1,
    name: "1",
  },
  {
    id: 2,
    value: 2,
    name: "2",
  },
  {
    id: 3,
    value: 3,
    name: "3",
  },
  {
    id: 4,
    value: 4,
    name: "4",
  },
];

export const SearchContents = [];

export const SearchActive = [];

export const SearchBoardList = [
  {
    id: 1,
    value: "RECORDING",
    name: "레코딩 게시판",
  },
  {
    id: 2,
    value: "DRAWING",
    name: "드로잉 게시판",
  },
  {
    id: 3,
    value: "WRITING",
    name: "라이팅 게시판",
  },
];

export const SearchReportList = [
  {
    id: 1,
    value: "COMMENT",
    name: "댓글",
  },
  {
    id: 2,
    value: "POST",
    name: "게시글",
  },
];

export const SearchDropdownList1 = [
  {
    id: 1,
    value: "RECORDING",
    name: "레코딩 이용권",
  },
  {
    id: 2,
    value: "DRAWING",
    name: "드로잉 이용권",
  },
  {
    id: 3,
    value: "WRITING",
    name: "라이팅 이용권",
  },
];

export const SearchDropdownList2 = [
  {
    id: 2,
    value: "true",
    name: "사용완료",
  },
  {
    id: 3,
    value: "false",
    name: "사용전",
  },
];
